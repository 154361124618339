import { Filters } from '../interfaces/filters';
import { Pagination } from '../interfaces/pagination';
import { getRegionLabel } from '../lib/regionSelectUtils';
import { buildCsdFilterElement } from '../lib/utilities';

export type Categories = 'AND' | 'OR' | '';
export type Comparators =
  | 'EQ'
  | 'NE'
  | 'GE'
  | 'LE'
  | 'LT'
  | 'GT'
  | 'LIKE'
  | 'RLIKE'
  | 'IN'
  | 'ISNULL';

export interface FilterElement {
  category?: Categories;
  conditionals?: FilterElement[];
  name?: string;
  value?: string;
  comparator?: Comparators;
}

export interface Filter {
  key: string;
  value?: string;
}

export interface FiltersAndPage {
  pagination: Pagination;
  filterElements: FilterElement;
}

export function newConditionalFilterElement(category?: Categories): FilterElement {
  return {
    category: category
  };
}

export function newLeafFilterElement(
  name: string,
  comparator: Comparators,
  value: string
): FilterElement {
  return { name, comparator, value };
}

export function addCondition(parent: FilterElement, child: FilterElement) {
  if (
    !child ||
    Object.keys(child).length === 0 ||
    ((child.category === 'AND' || child.category === 'OR') &&
      (!child.conditionals || child.conditionals.length < 1))
  ) {
    return;
  }

  if (!parent.conditionals) {
    parent.conditionals = [];
  }

  parent.conditionals.push(child);
}

export function simplifyFilterElement(element: FilterElement): FilterElement {
  if (!element.conditionals || element.conditionals.length < 1) {
    return {};
  }

  if (element.conditionals.length === 1) {
    return element.conditionals[0];
  }

  return element;
}

export function addConditionGroup(
  category: Categories,
  FilterElements: FilterElement,
  filters: Filter[]
) {
  let elements = newConditionalFilterElement(category);

  filters.map((filter) => {
    addCondition(elements, getLeafElementByKey(filter));
  });

  addCondition(FilterElements, simplifyFilterElement(elements));
}

export function getLeafElementByKey(filter: Filter): FilterElement {
  switch (filter.key) {
    // Exact Matches
    case 'providerId':
    case 'callReceivedFrom':
    case 'callSentTo':
    case 'campaignId':
    case 'labelId':
    case 'tracebackId':
    case 'status':
    case 'commentType':
      if (filter.value) {
        return newLeafFilterElement(filter.key, 'EQ', filter.value);
      } else {
        return {};
      }

    // True (1) or False (omitted)
    case 'live':
    case 'isNotDomestic':
    case 'isItgMember':
    case 'isActive':
    case 'isIor':
    case 'isOrig':
    case 'isPoe':
    case 'isFpd':
      return newLeafFilterElement(filter.key, 'EQ', '1');

    // Notification Comments
    case 'includeITGMember':
    case 'includeReminder':
    case 'includeFormal':
    case 'includeCooperating':
    case 'includeNonResponsive':
      return newLeafFilterElement('emailType', 'EQ', filter.value || '');

    // Date ranges
    case 'startDate':
      return newLeafFilterElement('startDate', 'GE', filter.value || '');

    case 'endDate':
      return newLeafFilterElement('endDate', 'LE', filter.value || '');

    case 'requestorId':
      return newLeafFilterElement('requestorId', 'EQ', filter.value || '');

    default:
      return {};
  }
}

export const getFilter = ({
  isItgMember,
  isNotDomestic,
  isLive,
  providerId,
  terminalRegion,
  termState,
  campaignId,
  campaignIds,
  labelId,
  reputations,
  status,
  csdInput,
  startDate,
  endDate,
  isActive,
  campaignDescription,
  sourceId,
  stirShakenInfo,
  country,
  requestorId,
  statusRequest,
  isInternational,
  callDestination,
  isStrikeExempt
}: Filters): FilterElement => {
  let filterElements = newConditionalFilterElement('AND');

  if (status && status.length > 0) {
    let statusElements = newConditionalFilterElement('OR');

    status.forEach((item: any) => {
      addCondition(statusElements, newLeafFilterElement('status', 'EQ', item.toString()));
    });

    addCondition(filterElements, simplifyFilterElement(statusElements));
  }

  if (isItgMember || isNotDomestic || isLive) {
    addConditionGroup('AND', filterElements, [
      { key: isItgMember ? 'isItgMember' : '' },
      { key: isNotDomestic ? 'isNotDomestic' : '' },
      { key: isLive ? 'live' : '' }
    ]);
  }

  if (providerId) {
    if (providerId === -1) {
      addCondition(filterElements, newLeafFilterElement('userRole', 'EQ', 'gov'));
    } else if (providerId === -2) {
      addCondition(filterElements, newLeafFilterElement('userRole', 'EQ', 'dno'));
    } else {
      addCondition(filterElements, newLeafFilterElement('providerId', 'EQ', providerId.toString()));
    }
  }

  if (terminalRegion) {
    if (terminalRegion === 'Non-Us') {
      addCondition(filterElements, newLeafFilterElement('country', 'NE', 'United States'));
    } else if (terminalRegion === 'All-Us') {
      addCondition(filterElements, newLeafFilterElement('country', 'EQ', 'United States'));
    } else {
      const region = getRegionLabel(terminalRegion);
      if (region) addCondition(filterElements, newLeafFilterElement('state', 'EQ', region));
    }
  }
  if (termState) {
    addCondition(filterElements, newLeafFilterElement('termState', 'EQ', termState));
  }
  if (isActive) {
    addCondition(filterElements, newLeafFilterElement('active', 'EQ', '1'));
  }

  if (campaignId) {
    addCondition(filterElements, newLeafFilterElement('campaignId', 'EQ', campaignId.toString()));
  }

  if (campaignDescription) {
    addCondition(filterElements, newLeafFilterElement('description', 'LIKE', campaignDescription));
  }

  if (sourceId) {
    addCondition(filterElements, newLeafFilterElement('sourceId', 'EQ', sourceId.toString()));
  }

  if (campaignIds && campaignIds.length > 1) {
    let idElements = newConditionalFilterElement('OR');

    for (let val in campaignIds) {
      if (!campaignIds[val]) break;

      addCondition(
        idElements,
        newLeafFilterElement('campaignId', 'EQ', campaignIds[val].toString())
      );
    }
    addCondition(filterElements, simplifyFilterElement(idElements));
  }

  if (labelId) {
    if (Array.isArray(labelId)) {
      if (labelId.length > 1)
        addCondition(filterElements, newLeafFilterElement('labelId', 'IN', labelId.join(',')));
      else if (labelId.length === 1 && labelId[0])
        addCondition(filterElements, newLeafFilterElement('labelId', 'EQ', labelId[0].toString()));
    } else addCondition(filterElements, newLeafFilterElement('labelId', 'EQ', labelId.toString()));
  }

  if (reputations) {
    if (reputations.length > 0 && reputations.length !== 3) {
      let reputationElements = newConditionalFilterElement('OR');
      const reputationTypes = ['isNoWarning', 'isYellow', 'isOrange'];
      reputations.forEach((item) => {
        addCondition(
          reputationElements,
          newLeafFilterElement(reputationTypes[item.value - 1], 'EQ', '')
        );
      });

      addCondition(filterElements, simplifyFilterElement(reputationElements));
    }
  }

  if (startDate || endDate) {
    addConditionGroup('AND', filterElements, [
      { key: startDate ? 'startDate' : '', value: startDate },
      { key: endDate ? 'endDate' : '', value: endDate }
    ]);
  }

  if (csdInput && csdInput.length) {
    buildCsdFilterElement(filterElements, csdInput);
  }

  if (callDestination && callDestination.length) {
    buildCsdFilterElement(filterElements, callDestination, true);
  }

  if (stirShakenInfo && stirShakenInfo.length) {
    addCondition(filterElements, newLeafFilterElement('stirShakenInfo', 'EQ', stirShakenInfo));
  }
  if (country && country !== 'All Countries') {
    addCondition(filterElements, newLeafFilterElement('country', 'EQ', country));
  }
  if (requestorId) {
    addCondition(filterElements, newLeafFilterElement('requestorId', 'EQ', requestorId.toString()));
  }

  if (statusRequest?.isPending && statusRequest?.isDeclined) {
    let statusElements = newConditionalFilterElement('OR');
    addCondition(statusElements, newLeafFilterElement('status', 'EQ', 'PENDING'));
    addCondition(statusElements, newLeafFilterElement('status', 'EQ', 'REJECTED'));
    addCondition(filterElements, simplifyFilterElement(statusElements));
  } else if (statusRequest?.isDeclined && !statusRequest?.isPending) {
    addCondition(filterElements, newLeafFilterElement('status', 'EQ', 'REJECTED'));
  } else if (!statusRequest?.isDeclined && statusRequest?.isPending) {
    addCondition(filterElements, newLeafFilterElement('status', 'EQ', 'PENDING'));
  }
  if (isInternational) {
    addCondition(filterElements, newLeafFilterElement('isInternational', 'EQ', '1'));
  }
  if (isStrikeExempt) {
    addCondition(filterElements, newLeafFilterElement('isStrikeExempt', 'EQ', '1'));
  }

  return simplifyFilterElement(filterElements);
};

export const removeFilterLeaf = (
  element: FilterElement,
  name: string,
  value: string
): FilterElement => {
  let newElement: FilterElement = {};
  if (element.name === name && element.value === value) return newElement;
  newElement = {
    category: element.category,
    conditionals: element.conditionals,
    name: element.name,
    value: element.value,
    comparator: element.comparator
  };
  if (newElement.conditionals && newElement.conditionals.length > 0) {
    newElement.conditionals = newElement.conditionals.map((v) => removeFilterLeaf(v, name, value));
    newElement.conditionals = newElement.conditionals.filter(
      (v) => (v.category && v.conditionals && v.conditionals.length > 0) || v.name
    );
  }
  return newElement;
};
