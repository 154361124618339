import { SelectOption } from '../components/CustomSelect';

export interface StirShakenInfo {
  toggleStirShaken?: boolean;
  stirShakenToken: string;
  signedStirShaken: null | SelectOption;
  attestationRadio: string;
  destinationNumber: string;
  callingNumber: string;
  timeStamp: string;
  originationIdentifier: string;
  isCallSigner: boolean;
  notSigned?: boolean;
}

export const defaultStirShakenInfo: StirShakenInfo = {
  stirShakenToken: '',
  signedStirShaken: null,
  attestationRadio: '',
  destinationNumber: '',
  callingNumber: '',
  timeStamp: '',
  originationIdentifier: '',
  isCallSigner: false
};

export interface StirShakenModalErrors {
  signedStirShaken: boolean;
  stirShakenToken: boolean;
  destinationNumber: boolean;
  callingNumber: boolean;
  timeStamp: boolean;
  attestation: boolean;
}

export interface StirShakenRequest {
  token?: string;
  attest?: string;
  dest?: string;
  iat?: string;
  orig?: string;
  origid?: string;
  isCallSigner: boolean;
  notSigned?: boolean;
}

export interface StirShakenDest {
  tn?: string[];
  uri?: string[];
}

interface StirShakenName {
  country: string[];
  organization: string[];
  organizationUnit: string[];
  commonName: string;
}

export interface StirShakenResponse {
  status: number;
  objectId: number;
  compact: boolean;
  token: string;
  attest: string;
  dest: StirShakenDest;
  hopID: number;
  hopSequence: number;
  hopCompletedDate: string;
  iat: string;
  orig: string;
  origid: string;
  errorMessage: null | string;
  isCallSigner: boolean;
  issuer: StirShakenName | undefined;
  signer: StirShakenName | undefined;
  isIssuerValid: boolean;
  certificateErrors: null | string;
  signerProviderId?: number;
  signerProviderName?: string;
  create_date: string;
  signerProviderCountry?: string;
  signerIsDomestic: boolean;
  signerIsITGMember: boolean;
}
export interface StirShakenSigner {
  signerOrganization?: string;
  signerOrganizationalUnit?: string;
  signerCommonName?: string;
  signerCountry?: string;
  providerName?: string;
  providerId?: number;
}

export interface StirShakenSearchRequest {
  name: string;
  isNotAssociated: boolean;
}

export const StirShakenSignerEqual = (a: StirShakenSigner, b: StirShakenSigner) =>
  a.signerCommonName === b.signerCommonName &&
  a.signerOrganization === b.signerOrganization &&
  a.signerOrganizationalUnit === b.signerOrganizationalUnit;

export interface StirShakenProviderNotInCallPathRequest {
  startDate: string;
  endDate: string;
  providerId?: number;
  originOnly?: boolean;
}

export interface StirShakenProviderNotInCallPathStats {
  providerId: number;
  signedStirShakens: number;
  tracebackCount: number;
  rmdNumber?: string;
  rmdSysId?: string;
  providerName: string;
  isITGMember: boolean;
  providerCountry: string;
}

export interface StirShakenProviderNotInCallPathTraceback {
  providerId: number;
  attest: string;
  signerCommonName: string;
  issuerCommonName: string;
  tracebackTime: Date;
  tracebackId: number;
  campaignName: string;
  campaignLabel?: string;
  campaignId: number;
}
