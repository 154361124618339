import React, { Dispatch, FC } from 'react';
import { ProviderItem } from '../../ProvidersSelect';
import { steps } from './DefaultStep';
import { Button } from 'reactstrap';
import { CallSource } from '../../../interfaces/callSource';
import StirShakenBody from '../../StirShakenBody';
import {
  defaultStirShakenInfo,
  StirShakenInfo,
  StirShakenModalErrors
} from '../../../interfaces/stirShaken';
import { getClientFormattedDate, validateStirShakenInputFields } from '../../../lib/utilities';
import { defaultStirShakenErrors } from '../../modals/StirShakenModal';
import { Hop } from '../../../interfaces/hop';
import { DateFormat } from '../../../enum/DateFormat';
import AddHopComment from '../../comments/AddHopComment';
import { Traceback } from '../../../interfaces/traceback';

interface IProps {
  modalStep: modalsSteps;
  setModalStep: Dispatch<React.SetStateAction<modalsSteps>>;
  hop: Hop;
  traceback: Traceback;
  setViewStep: Dispatch<React.SetStateAction<steps>>;
  customerEmailProvider?: ProviderItem;
  setSelectedProvider: Dispatch<React.SetStateAction<number>>;
  next: (customerEmailConfirmation?: boolean) => Promise<void>;
  callSource: CallSource;
  setCallSource: Dispatch<React.SetStateAction<CallSource>>;
  submitCallSourceToggle: () => void;
  stirShakenInfo: StirShakenInfo;
  stirShakenModalErrors: StirShakenModalErrors;
  setStirShakenInfo: Dispatch<React.SetStateAction<StirShakenInfo>>;
  setStirShakenModalErrors: Dispatch<React.SetStateAction<StirShakenModalErrors>>;
}

export enum modalsSteps {
  default = 'default',
  customerMatch = 'customerMatch',
  helpFindingRecord = 'helpFindingRecord',
  noCompleteAddress = 'noCompleteAddress',
  stirShakenModal = 'stirShakenModal',
  upstreamProviderSelected = 'upstreamProviderSelected',
  providerInSequence = 'providerInSequence'
}

const buttonNameMapper = {
  [modalsSteps.customerMatch]: 'Select as Upstream Provider',
  [modalsSteps.noCompleteAddress]: 'Continue',
  [modalsSteps.stirShakenModal]: 'Submit',
  [modalsSteps.providerInSequence]: 'Submit Anyway'
};

const HopResponseModalsSubmit: FC<IProps> = ({
  modalStep,
  setModalStep,
  hop,
  traceback,
  customerEmailProvider,
  setViewStep,
  setSelectedProvider,
  next,
  callSource,
  setCallSource,
  submitCallSourceToggle,
  stirShakenInfo,
  stirShakenModalErrors,
  setStirShakenInfo,
  setStirShakenModalErrors
}) => {
  const nextModal = () => {
    switch (modalStep) {
      case modalsSteps.customerMatch:
        setViewStep(steps.upstreamVoice);
        setSelectedProvider(customerEmailProvider?.id);
        setModalStep(modalsSteps.default);
        break;
      case modalsSteps.noCompleteAddress:
        traceback.isInternational
          ? submitCallSourceToggle()
          : setModalStep(modalsSteps.stirShakenModal);
        break;
      case modalsSteps.stirShakenModal:
        const stirShakenErrors = validateStirShakenInputFields(
          stirShakenModalErrors,
          stirShakenInfo
        );
        if (stirShakenErrors) {
          setStirShakenModalErrors(stirShakenErrors);
          break;
        }
        submitCallSourceToggle();
        break;
      case modalsSteps.providerInSequence:
        traceback.isInternational
          ? submitCallSourceToggle()
          : setModalStep(modalsSteps.stirShakenModal);
        break;
      default:
        break;
    }
  };
  const cancel = () => {
    setModalStep(modalsSteps.default);
    switch (modalStep) {
      case modalsSteps.noCompleteAddress:
        setCallSource((v) => ({ ...v, reasonRejection: '' }));
        break;
      case modalsSteps.stirShakenModal:
        setStirShakenInfo(defaultStirShakenInfo);
        setStirShakenModalErrors(defaultStirShakenErrors);
        break;
      default:
        break;
    }
  };
  return (
    <div>
      {modalStep === modalsSteps.customerMatch ? (
        <div>
          <label className="telecom-label extra">Customer matches provider</label>
          <div className="w-75">
            <p>
              It appears that the enduser/caller information matches with an existing Voice Service
              Provider,{' '}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  nextModal();
                }}
              >
                {customerEmailProvider?.name}
              </a>
              . If this is correct please select them as your upstream. Otherwise press 'Submit As
              Enduser' to continue.
            </p>
          </div>
        </div>
      ) : modalStep === modalsSteps.noCompleteAddress ? (
        <>
          <label className="telecom-label extra">Complete Address not provided !</label>
          <div className="row mb-2">
            <div className="col">
              <label className="label-bold">List of missing fields :</label>
              <ul>
                {callSource.addressLine1 === '' ? <li>Address</li> : ''}
                {callSource.city === '' ? <li>City</li> : ''}
                {callSource.region === '' ? <li>Region</li> : ''}
                {callSource.postalCode === '' ? <li>Zip/Postal Code</li> : ''}
                {callSource.ipAddress === '0.0.0.0' ? (
                  <li className="text-red">Calling Party IP Address: 0.0.0.0</li>
                ) : (
                  ''
                )}
              </ul>
            </div>
            <div className="col">
              <div>
                <label className="label-bold">Choose one*</label>
              </div>
              <input
                type="radio"
                name="reason"
                onChange={() => setCallSource((v) => ({ ...v, reasonRejection: 'do_not_collect' }))}
              />
              <span className="p-2">Do not collect</span>
              <div>
                <input
                  type="radio"
                  name="reason"
                  onChange={() =>
                    setCallSource((v) => ({ ...v, reasonRejection: 'unwilling_to_share' }))
                  }
                />
                <span className="p-2">Unwilling to share</span>
              </div>
            </div>
          </div>
        </>
      ) : modalStep === modalsSteps.stirShakenModal ? (
        <StirShakenBody
          setStirShakenInfo={setStirShakenInfo}
          stirShakenInfo={stirShakenInfo}
          stirShakenModalErrors={stirShakenModalErrors}
          setStirShakenModalErrors={setStirShakenModalErrors}
        />
      ) : modalStep === modalsSteps.helpFindingRecord ? (
        <>
          <label className="telecom-label extra">Help Finding Record</label>
          <p>
            It's critical to find the source of the traceback. If you are unable to locate the call
            based on the details here:
          </p>
          <p>TIME: {getClientFormattedDate(traceback.tracebackTime, DateFormat.LongBoth)}</p>
          <p>CALLING: {traceback.callingTN}</p>
          <p>CALLED: {traceback.calledTN}</p>
          <p>
            Please use the comment area here to message connecting partner to request information
            such as p-cap, IP address or anything else that will help locate the call record.
          </p>
          <h5 className="p-0">
            {`${hop.hopProvider.name} → ${hop.downstreamProvider?.name} & ITG Administrators`}:
          </h5>
          <AddHopComment
            isCallSourcePopulated={true}
            hopID={hop.hopId}
            hopProviderName={hop.hopProvider.name}
            upstreamProviderName={hop.upstreamProvider ? hop.upstreamProvider.name : ''}
            downstreamProviderName={hop.downstreamProvider ? hop.downstreamProvider.name : ''}
            forceDirectedTo="downstream"
            uniqueId="button_modal"
            cancel={cancel}
          />
        </>
      ) : modalStep === modalsSteps.upstreamProviderSelected ? (
        <>
          <label className="telecom-label extra">Warning - Upstream Provider Selected.</label>
          <p>{`The upstream provider you selected is the same as the downstream provider, ${hop.downstreamProvider?.name}.`}</p>
          <p>{`Please use the comment area here to message the downstream provider ${hop.downstreamProvider?.name} to ensure this is correct.`}</p>
          <AddHopComment
            isCallSourcePopulated={true}
            hopID={hop.hopId}
            hopProviderName={hop.hopProvider.name}
            upstreamProviderName={hop.upstreamProvider ? hop.upstreamProvider.name : ''}
            downstreamProviderName={hop.downstreamProvider ? hop.downstreamProvider.name : ''}
            forceDirectedTo="downstream"
            uniqueId="downstream_modal"
            cancel={cancel}
          />
        </>
      ) : modalStep === modalsSteps.providerInSequence ? (
        <>
          <label className="telecom-label extra">
            This provider has already participated on an earlier hop in this traceback.
          </label>
          <p className="text-center text-md-left mt-4 mb-n3">
            Are you sure you want to continue ?<br />
            Please send an email to{' '}
            <a href="mailto:support@tracebacks.org">support@tracebacks.org</a> if you believe there
            is an error.
          </p>
        </>
      ) : (
        <></>
      )}
      {modalStep !== modalsSteps.default &&
        modalStep !== modalsSteps.helpFindingRecord &&
        modalStep !== modalsSteps.upstreamProviderSelected && (
          <div className="d-flex justify-content-center flex-wrap">
            <Button className="btn-default telecom-btn" color="light" onClick={cancel}>
              Cancel
            </Button>
            {modalStep === modalsSteps.customerMatch && (
              <Button
                className="btn-default telecom-btn ms-2"
                color="light"
                onClick={() => {
                  next(true);
                }}
              >
                Submit As Enduser
              </Button>
            )}
            <Button
              className="telecom-btn ms-2"
              disabled={
                modalStep === modalsSteps.noCompleteAddress && callSource.reasonRejection === ''
              }
              onClick={nextModal}
            >
              {buttonNameMapper[modalStep]}
            </Button>
          </div>
        )}
    </div>
  );
};

export default HopResponseModalsSubmit;
