import React from 'react';

export enum steps {
  default = 'default',

  downstream = 'downstream',
  rmdSearch = 'rmdSearch',
  newProvider = 'newProvider',
  callDestination = 'callDestination',
  callRecordNotProvided = 'callRecordNotProvided'
}

const choices = [
  {
    text: 'Voice Service Provider',
    value: steps.downstream
  },
  {
    text: 'Called party / End user / subscriber',
    value: steps.callDestination
  },
  {
    text: 'Call record not provided',
    value: steps.callRecordNotProvided
  }
];

interface IProps {
  selectedCallDestination: steps;
  setSelectedCallDestination: (s: steps) => void;
}

const DefaultStep: React.FC<IProps> = ({ selectedCallDestination, setSelectedCallDestination }) => (
  <form>
    {choices.map((v) => (
      <div key={`defaultStepKey-${v.value}`}>
        <input
          type="radio"
          name={v.value}
          onChange={(e) => {
            setSelectedCallDestination(v.value);
          }}
          checked={v.value == selectedCallDestination}
        />{' '}
        <label key={v.value} style={{ fontSize: '15px' }}>
          {v.text}
        </label>
      </div>
    ))}
  </form>
);

export default DefaultStep;
