import { connect } from 'react-redux';
import { signOut } from '../redux/auth/thunks';

interface IProps {
  signOut: Function;
}

const SignOutElement: React.FC<IProps> = ({ signOut }) => {
  signOut();
  return null;
};

const mapActionsToProps = { signOut };

export default connect(null, mapActionsToProps)(SignOutElement);
