import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import AdminTable, { CustomModal } from '../components/AdminDashboard/Users/AdminTable';
import BaseCard from '../components/BaseCard';
import Dot from '../components/dot';
import InputFormGroup from '../components/inputFormGroup';
import { userTypes } from '../enum/userTypes';
import { PersonalInfo } from '../interfaces/user';
import {
  validLengthRegex,
  validLowercaseRegex,
  validNumberRegex,
  validSpecialRegex,
  validUppercaseRegex
} from '../lib/regex';
import { isValidUsername } from '../lib/utilities';
import { deactivateAccountInfo, editAccountInfo, signOut } from '../redux/auth/thunks';
import { stateMappings } from '../redux/stateMappings';

interface User {
  name: string;
  email: string;
  oldPassword: string;
  newPassword: string;
  retypeNewPassword: string;
}

interface Error {
  name: string;
  oldPassword: string;
  newPassword: string;
  retypeNewPassword: string;
  password: PasswordError;
}

interface PasswordError {
  lowercase: boolean;
  uppercase: boolean;
  special: boolean;
  minimum: boolean;
  number: boolean;
}

interface IProps {
  user: PersonalInfo;
  editAccountInfo: Function;
  deactivateAccountInfo: Function;
  changePasswordError: string;
  signOut: Function;
}

const defaultError = (): Error => ({
  name: '',
  oldPassword: '',
  newPassword: '',
  retypeNewPassword: '',
  password: {
    lowercase: false,
    uppercase: false,
    special: false,
    minimum: false,
    number: false
  }
});

const newPasswordValidation = (
  newPassword: string,
  oldPassword: string
): [string, PasswordError] => {
  const error: PasswordError = {
    lowercase: validLowercaseRegex.test(newPassword),
    uppercase: validUppercaseRegex.test(newPassword),
    special: validSpecialRegex.test(newPassword),
    number: validNumberRegex.test(newPassword),
    minimum: validLengthRegex.test(newPassword)
  };
  if (newPassword === oldPassword) {
    return ['New password cannot be the same as your old password.', error];
  }
  if (!(error.lowercase && error.uppercase && error.minimum && error.special && error.number)) {
    return ['Please provide a valid new password.', error];
  }
  return ['', error];
};

const validateUser = (user: User): Error => {
  let error: Error = {
    ...defaultError(),
    name: user.name && isValidUsername(user.name) ? '' : 'Please provide a valid full name.'
  };

  if (user.newPassword || user.oldPassword || user.retypeNewPassword) {
    [error.newPassword, error.password] = newPasswordValidation(user.newPassword, user.oldPassword);
    error.oldPassword = user.oldPassword ? '' : 'Please provide a valid current password';
    error.retypeNewPassword =
      user.retypeNewPassword && user.newPassword === user.retypeNewPassword
        ? ''
        : 'Passwords must match';
  }
  return error;
};

const Account: React.FC<IProps> = ({
  user,
  editAccountInfo,
  deactivateAccountInfo,
  changePasswordError,
  signOut
}) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const [userInfo, setUserInfo] = useState<User>({
    name: user.name,
    email: user.email,
    oldPassword: '',
    newPassword: '',
    retypeNewPassword: ''
  });
  const [error, setError] = useState<Error>(defaultError());
  const magicLinkLogin = useMemo(() => !!localStorage.getItem('magicLinkLogIn'), []);

  useEffect(() => {
    setError(validateUser(userInfo));
  }, [userInfo]);

  useEffect(() => {
    if (changePasswordError === 'IncorrectPasswordError')
      setError({ ...error, oldPassword: 'Please provide a valid current password' });
  }, [changePasswordError]);

  useEffect(() => {
    if (!isEditMode) return;
    setUserInfo({
      name: user.name,
      email: user.email,
      oldPassword: '',
      newPassword: '',
      retypeNewPassword: ''
    });
  }, [isEditMode]);

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { name, oldPassword, newPassword, retypeNewPassword } = error;

    if (name || oldPassword || newPassword || retypeNewPassword) {
      return;
    }

    editAccountInfo(
      {
        ...user,
        id: user.id,
        name: userInfo.name,
        email: userInfo.email
      },
      userInfo.oldPassword,
      userInfo.newPassword
    );
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    const { name, value } = e.currentTarget;

    switch (name) {
      case 'name': {
        setUserInfo({
          ...userInfo,
          name: value
        });
        break;
      }
      case 'oldPassword': {
        setUserInfo({
          ...userInfo,
          oldPassword: value
        });
        break;
      }
      case 'newPassword': {
        setUserInfo({
          ...userInfo,
          newPassword: value
        });
        break;
      }
      case 'retypeNewPassword': {
        setUserInfo({
          ...userInfo,
          retypeNewPassword: value
        });
        break;
      }
      default:
        break;
    }
  };

  const editForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (magicLinkLogin) {
      signOut();
      return;
    }
    setIsEditMode((v) => !v);
  };

  const deactivateUser = () => {
    deactivateAccountInfo(user.id);
  };
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen((v) => !v);
  };

  return (
    <Container>
      {isEditMode && (
        <Row className="justify-content-end pe-5 pt-4 pb-3">
          <p className="telecom-clickable_text mb-0" id="tooltip-deactivate" onClick={toggleModal}>
            Deactivate Account
          </p>
          <CustomModal
            isOpen={modalOpen}
            toggleModal={toggleModal}
            message={
              'Are you sure you want to deactivate this account? This action cannot be undone.'
            }
            action={deactivateUser}
          />
        </Row>
      )}
      <BaseCard
        containerClassnames={`card-account ${isEditMode ? 'mt-0' : ''}`}
        headerTitle="My Account"
      >
        <div className="d-flex justify-content-center">
          <Col lg="4">
            <Form
              onSubmit={submitForm}
              className={isEditMode ? 'form-account form-fadein' : 'pt-4'}
            >
              <Label className="telecom-label">
                {isEditMode ? 'Full Name' : 'Name'}
                {isEditMode && <i className="fa fa-asterisk asterisk" />}
              </Label>
              {isEditMode ? (
                <InputFormGroup
                  inputName="name"
                  inputId="name"
                  inputValue={userInfo.name}
                  inputOnChange={handleChange}
                  inputPlaceholder=""
                  inputAutoComplete="off"
                  errorMessage={error.name}
                />
              ) : (
                <FormGroup>
                  <Input readOnly className="my-account-input" value={userInfo.name} />
                </FormGroup>
              )}
              <Label className="telecom-label">Email</Label>
              <FormGroup>
                <Input readOnly className="my-account-input" value={userInfo.email} />
              </FormGroup>
              {isEditMode && (
                <Fragment>
                  <Label className="telecom-label">Current Password</Label>
                  <InputFormGroup
                    isPassword
                    inputName="oldPassword"
                    inputId="old-password"
                    inputValue={userInfo.oldPassword}
                    inputOnChange={handleChange}
                    inputPlaceholder=""
                    inputAutoComplete="off"
                    errorMessage={error.oldPassword}
                  ></InputFormGroup>
                </Fragment>
              )}
              <Label className="telecom-label">{isEditMode ? 'New Password' : 'Password'}</Label>
              {isEditMode ? (
                <InputFormGroup
                  isPassword
                  inputName="newPassword"
                  inputId="new-password"
                  inputValue={userInfo.newPassword}
                  inputOnChange={handleChange}
                  inputPlaceholder=""
                  inputAutoComplete="off"
                  errorMessage={error.newPassword}
                ></InputFormGroup>
              ) : (
                <FormGroup>
                  <Input readOnly className="my-account-input" value="***********" />
                </FormGroup>
              )}

              {isEditMode && (
                <Fragment>
                  <Label className="telecom-label">Re-type Password</Label>
                  <InputFormGroup
                    isPassword
                    inputName="retypeNewPassword"
                    inputId="retype-password"
                    inputValue={userInfo.retypeNewPassword}
                    inputOnChange={handleChange}
                    inputPlaceholder=""
                    inputAutoComplete="off"
                    errorMessage={error.retypeNewPassword}
                  />
                </Fragment>
              )}
              {isEditMode && (
                <FormGroup className="password-validation">
                  <Row>
                    <div className={error.password.lowercase ? 'inactive' : ''}>
                      <Dot color={error.password.lowercase ? '#e4e4e4' : '#54b9cf'} />
                      <span>{` One lowercase character`}</span>
                    </div>
                    <div className={error.password.uppercase ? 'inactive' : ''}>
                      <Dot color={error.password.uppercase ? '#e4e4e4' : '#54b9cf'} />
                      <span>{` One uppercase character`}</span>
                    </div>
                    <div className={error.password.special ? 'inactive' : ''}>
                      <Dot color={error.password.special ? '#e4e4e4' : '#54b9cf'} />
                      <span>{` One special character`}</span>
                    </div>
                    <div className={error.password.minimum ? 'inactive' : ''}>
                      <Dot color={error.password.minimum ? '#e4e4e4' : '#54b9cf'} />
                      <span>{` 8 characters minimum`} </span>
                    </div>
                    <div className={error.password.number ? 'inactive' : ''}>
                      <Dot color={error.password.number ? '#e4e4e4' : '#54b9cf'} />
                      <span>{` One number`}</span>
                    </div>
                  </Row>
                </FormGroup>
              )}
              {user.roleType === userTypes.ProviderManager && !magicLinkLogin && (
                <div className="d-flex justify-content-center">
                  <a href={`/providers/provider/${user.providerId}#edit`} className="ms-2">
                    Add team members on you provider detail page
                  </a>
                </div>
              )}
              <div className="d-flex justify-content-center">
                {isEditMode ? (
                  <Fragment>
                    <Button className="btn-join" color="light" onClick={editForm}>
                      Cancel
                    </Button>
                    <Button type="submit" className="btn-join">
                      Submit
                    </Button>
                  </Fragment>
                ) : (
                  <>
                    <Button className="btn-join" onClick={editForm}>
                      {`${magicLinkLogin ? 'Confirm Password' : 'Edit'}`}
                    </Button>
                    {magicLinkLogin && (
                      <span className="text-danger mt-2">
                        You are accessing the site with a magic-link, some features will be disabled
                        until you sign in with your password
                      </span>
                    )}
                  </>
                )}
              </div>
            </Form>
          </Col>
          {user.roleType === userTypes.Admin && (
            <AdminTable
              isEditMode={isEditMode}
              user={user}
              deactivateAccountInfo={deactivateAccountInfo}
            />
          )}
        </div>
      </BaseCard>
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    user: sm.user,
    changePasswordError: sm.changePasswordError
  };
};

const mapActionsToProps = {
  editAccountInfo,
  deactivateAccountInfo,
  signOut
};

export default connect(mapStateToProps, mapActionsToProps)(Account);
