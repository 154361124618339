import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CallSource, NotProvidedReason, emptyCallSource } from '../../interfaces/callSource';
import {
  CampaignAttributes,
  DisputeInfo,
  emptyCampaignAttributes
} from '../../interfaces/campaign';
import { DisputeStatus, emptyNewHopProvider, Hop, HopDispute } from '../../interfaces/hop';
import {
  defaultStirShakenInfo,
  StirShakenInfo,
  StirShakenModalErrors
} from '../../interfaces/stirShaken';
import { Traceback } from '../../interfaces/traceback';
import { PersonalInfo } from '../../interfaces/user';
import {
  ValidationRules,
  isFormValid,
  minLengthRule,
  regexRule,
  requiredRule,
  useValidator
} from '../../lib/hooks';
import { validatePhoneNumber } from '../../lib/phoneNumber';
import { validEmailRegex, validateIpAddress } from '../../lib/regex';
import {
  generateStirShakenRequestObj,
  getApiFormattedDate,
  isNotReservedIPv4,
  validateStirShakenInputFields
} from '../../lib/utilities';
import { addCommentObject } from '../../redux/comment/thunks';
import {
  getProviderIdForEmail,
  getProviderInSequenceStatusApiCall
} from '../../redux/hop/apiCalls';
import { stateMappings } from '../../redux/stateMappings';
import { defaultStirShakenErrors } from '../modals/StirShakenModal';
import CallSourceCustomerForm from './CallSourceCustomerForm';
import { ProviderItem } from '../ProvidersSelect';
import UpstreamVoiceStep from './FormSteps/UpstreamVoiceStep';
import CallRecordStep from './FormSteps/CallRecordStep';
import DefaultStep, { steps } from './FormSteps/DefaultStep';
import RmdSearcher from '../RmdSearcher';
import { RMDEntry } from '../../interfaces/rmd';
import NewProviderStep, {
  GetZeroBounceErrors,
  newProviderValidator,
  ZerobounceErrors
} from '../TfHopDetail/FormSteps/NewProviderStep';
import HopResponseModalsSubmit, { modalsSteps } from './FormSteps/HopResponseModalsSubmit';

export const customerValidator: ValidationRules = {
  businessName: [
    {
      rule: requiredRule,
      message: 'The field is required.'
    }
  ],
  contactEmail: [
    {
      rule: regexRule(validEmailRegex),
      message: 'An email address is required.'
    }
  ],
  country: [
    {
      rule: requiredRule,
      message: 'The field is required.'
    }
  ],
  actionTaken: [
    {
      rule: requiredRule,
      message: 'The field is required.'
    }
  ],
  stepsTaken: [
    {
      rule: minLengthRule(5),
      message: 'The field is required.'
    }
  ],
  ipAddress: [
    {
      rule: validateIpAddress,
      message: "That's not a valid IP address."
    },
    { rule: isNotReservedIPv4, message: "That's a reserved IP Address." }
  ]
};

const emptyDisputeInfo: DisputeInfo = {
  disputeVisible: false,
  attributes: emptyCampaignAttributes(),
  comment: '',
  consentGiven: false,
  attachments: []
};

interface IProps {
  hop: Hop;
  traceback: Traceback;
  user: PersonalInfo;
  updateHopObject: Function;
  providersData: ProviderItem[];
  onChange: (isCallSourcePopulated: boolean) => void;
  campaignAttribute: CampaignAttributes;
  callSourceClassName: string;
}

const CallSourceRow: React.FC<IProps> = ({
  hop,
  traceback,
  user,
  updateHopObject,
  onChange,
  providersData,
  campaignAttribute,
  callSourceClassName
}) => {
  const [step, setStep] = useState(steps.default);
  const [modalStep, setModalStep] = useState(modalsSteps.default);
  const [currentCallSource, setCurrentCallSource] = useState(steps.default);
  const [selectedProvider, setSelectedProvider] = useState(0);
  const selectedProviderData = providersData.find((v) => v.id === selectedProvider);
  const [extraContactEmail, setExtraContactEmail] = useState('');
  const [extraCommentRmd, setExtraCommentRmd] = useState('');
  const [extraContactEmailError, setExtraContactEmailError] = useState('');
  const [extraCommentRmdError, setExtraCommentRmdError] = useState('');

  const [callOriginatorAndCallingParty, setCallOriginatorAndCallingParty] = useState(false);
  const [callSource, setCallSource] = useState<CallSource>(emptyCallSource());
  const [validateCallSourceErrors, clearCallSourceErrors] = useValidator(customerValidator);
  const [disputeInfo, setDisputeInfo] = useState<DisputeInfo>(emptyDisputeInfo);

  const [newTracebackTime, setNewTracebackTime] = useState('');
  const [forwardedCall, setForwardedCall] = useState('');
  const [rmdBusinessName, setRmdBusinessName] = useState('');
  const [validateNewProviderErrors, clearNewProviderErrors] = useValidator(newProviderValidator);

  const [newProvider, setNewProvider] = useState(emptyNewHopProvider());
  const [zeroBounceErrors, setZeroBounceErrors] = useState<ZerobounceErrors>({
    contactEmail: '',
    extraContactEmail: ''
  });

  const [stirShakenInfo, setStirShakenInfo] = useState<StirShakenInfo>(defaultStirShakenInfo);
  const [stirShakenModalErrors, setStirShakenModalErrors] =
    useState<StirShakenModalErrors>(defaultStirShakenErrors);
  const [customerEmailProvider, setCustomerEmailProvider] = useState<ProviderItem>();
  const [similarProviders, setSimilarProviders] = useState<ProviderItem[]>([]);

  // modals controls
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSimilarProvidersModalOpen, setSimilarProvidersIsModalOpen] = useState(false);
  const toggleSimilarProvidersModal = () => setSimilarProvidersIsModalOpen((v) => !v);

  const zeroBounceCheckEmail = async (email: string, isExtra?: boolean) => {
    const response = await GetZeroBounceErrors(email, isExtra);
    setZeroBounceErrors((v) => ({
      contactEmail: isExtra ? v.contactEmail : response,
      extraContactEmail: isExtra ? response : v.extraContactEmail
    }));
    return response;
  };

  useEffect(() => {
    onChange(currentCallSource === steps.upstreamVoice ? !!selectedProvider : !!currentCallSource);
  }, [currentCallSource, selectedProvider]);

  useEffect(() => {
    if (!selectedProviderData?.badEmails) return;

    if (!extraContactEmail)
      setExtraContactEmailError(
        `Please submit an alternative email for the selected voice service provider.`
      );
    else if (!validEmailRegex.test(extraContactEmail))
      setExtraContactEmailError('Please provide a valid email address');
    else setExtraContactEmailError('');
  }, [selectedProviderData?.badEmails, extraContactEmail]);

  useEffect(() => {
    if (
      traceback.isInternational ||
      !selectedProviderData?.noRmd ||
      user.providerCountry !== 'United States'
    )
      return;
    if (!extraCommentRmd)
      setExtraCommentRmdError(
        `Please note that the selected provider is not registered in the FCC's Robocall Mitigation Database. If you feel this is an error please add the RMD number or name of entity here.`
      );
    else if (extraCommentRmd.length <= 5)
      setExtraCommentRmdError('Comment must have at least 5 characters');
    else setExtraCommentRmdError('');
  }, [
    selectedProviderData?.noRmd,
    extraCommentRmd,
    user.providerCountry,
    traceback.isInternational
  ]);

  useEffect(() => {
    if (
      callSource.currentCause === 'isRecordUn' &&
      hop.comments.filter((v) => v.directedTo === 'downstream').length === 0 &&
      !!hop.downstreamHopId
    ) {
      setModalStep(modalsSteps.helpFindingRecord);
    }
  }, [callSource.currentCause]);

  const handleProviderChange = (e: any) => {
    if (e && providersData.some((v) => v.active && v.id == e.value)) {
      setSelectedProvider(e.value);
    } else {
      setSelectedProvider(0);
    }
  };

  const handleCallSourceInputChange = (e: any) => {
    if (e !== null && e.currentTarget) {
      const { name, value } = e.currentTarget;
      switch (name) {
        case 'explanation': {
          setCallSource((v) => ({ ...v, explanation: value }));
          break;
        }
        case 'extraContactEmail':
          setExtraContactEmail(value);
          break;
        case 'extraCommentRmd':
          setExtraCommentRmd(value);
          break;
        default:
          break;
      }
    }
  };

  const canSubmit = (): boolean => {
    switch (step) {
      case steps.upstreamVoice:
        return (
          providersData.length > 0 &&
          (!selectedProviderData?.badEmails || !extraContactEmailError) &&
          (traceback.isInternational ||
            !selectedProviderData?.noRmd ||
            user.providerCountry !== 'United States' ||
            !extraCommentRmdError) &&
          !!selectedProvider
        );
      case steps.callingParty:
        return (
          validateIpAddress(callSource.ipAddress) &&
          isNotReservedIPv4(callSource.ipAddress) &&
          callSource.ipAddress !== '' &&
          isFormValid(customerValidator, callSource) &&
          (disputeInfo.consentGiven
            ? !!disputeInfo.comment ||
              (disputeInfo.attachments && disputeInfo.attachments.length > 0)
            : true)
        );
      case steps.callRecordNotProvided:
        return !!(callSource.explanation && callSource.currentCause);
    }
    return false;
  };

  const handleCauseChange = (e: any) => {
    const value = e.currentTarget.value;
    const imposterMessage =
      hop.downstreamHopId && value === NotProvidedReason.imposter
        ? `Evidence that ${hop.hopProvider.name} was being impersonated to send traffic to ${hop.downstreamProvider.name}. Assigning this hop to 'Imposter' provider and marking traceback as No Response`
        : '';
    setCallSource((v) => ({
      ...v,
      currentCause: value,
      explanation: imposterMessage
    }));
  };

  useEffect(() => {
    setCustomerEmailProvider(undefined);
  }, [callSource.contactEmail]);

  const next = async (customerEmailConfirmation: boolean = false) => {
    if (forwardedCall && !validatePhoneNumber(forwardedCall)) {
      return;
    }
    switch (step) {
      case steps.default:
        setStep(currentCallSource);
        break;
      case steps.upstreamVoice:
        try {
          if (selectedProviderData?.badEmails) {
            const zeroBounceError = await GetZeroBounceErrors(extraContactEmail, true);
            if (zeroBounceError) {
              setExtraContactEmailError(zeroBounceError);
              return;
            }
          }
          const providerAlreadyInSequence = await getProviderInSequenceStatusApiCall(
            hop.tracebackId,
            selectedProvider
          );
          if (
            !!hop.downstreamProvider &&
            selectedProvider === hop.downstreamProvider.providerId &&
            hop.comments.filter((v) => v.directedTo === 'downstream').length === 0
          ) {
            setModalStep(modalsSteps.upstreamProviderSelected);
            return;
          }
          if (providerAlreadyInSequence.data.data) {
            setModalStep(modalsSteps.providerInSequence);
            return;
          }
          traceback.isInternational
            ? submitCallSourceToggle()
            : setModalStep(modalsSteps.stirShakenModal);
        } catch (error: any) {
          console.log('Error:', error);
        }
        break;
      case steps.callingParty:
        if (!customerEmailConfirmation) {
          if (!customerEmailProvider) {
            try {
              const rsp = await getProviderIdForEmail({
                email: callSource.contactEmail,
                hopId: hop.hopId,
                tfhopId: 0
              });
              if (rsp.data) {
                const provider = providersData.find((v) => v.id === rsp.data);
                setCustomerEmailProvider(provider);
                if (!!provider) {
                  setModalStep(modalsSteps.customerMatch);
                  return;
                }
              }
            } catch (error: any) {
              console.log('Error:', error);
            }
          } else {
            setModalStep(modalsSteps.customerMatch);
            return;
          }
        }
        if (
          callSource.addressLine1 === '' ||
          callSource.city === '' ||
          (callSource.region === '' && callSource.country !== 'Unknown') ||
          callSource.postalCode === '' ||
          callSource.ipAddress === '0.0.0.0'
        ) {
          setModalStep(modalsSteps.noCompleteAddress);
        } else {
          traceback.isInternational
            ? submitCallSourceToggle()
            : setModalStep(modalsSteps.stirShakenModal);
        }
        break;
      case steps.callRecordNotProvided:
        if (
          callSource.currentCause === 'isRecordUn' &&
          hop.comments.filter((v) => v.directedTo === 'downstream').length === 0 &&
          !!hop.downstreamHopId
        ) {
          setModalStep(modalsSteps.helpFindingRecord);
          return;
        }
        setIsSubmit(true);
        break;
      case steps.rmdSearch:
        setStep(steps.newProvider);
        break;
      case steps.newProvider: {
        const errors = validateNewProviderErrors(newProvider, true);
        const stirShakenErrors = validateStirShakenInputFields(
          stirShakenModalErrors,
          stirShakenInfo
        );
        setStirShakenModalErrors(stirShakenErrors || defaultStirShakenErrors);
        if (
          !!errors.newProviderName ||
          !!errors.newProviderContactName ||
          !!errors.newProviderCountry ||
          !!errors.newProviderContactEmail ||
          (!!errors.acknowledge &&
            zeroBounceErrors.contactEmail &&
            !!errors.newProviderExtraContactEmail) ||
          (!!errors.acknowledge && !newProvider.newRmd) ||
          stirShakenErrors?.attestation ||
          stirShakenErrors?.callingNumber ||
          stirShakenErrors?.destinationNumber ||
          stirShakenErrors?.signedStirShaken ||
          stirShakenErrors?.stirShakenToken ||
          stirShakenErrors?.timeStamp
        )
          return;
        if (
          !!newProvider.newRmd &&
          !!zeroBounceErrors.contactEmail &&
          (await zeroBounceCheckEmail(newProvider.newProviderExtraContactEmail, true))
        )
          return;
        else if (await zeroBounceCheckEmail(newProvider.newProviderContactEmail)) return;

        const filteredProviders = providersData.filter((providerElement: ProviderItem) =>
          providerElement.name.toLowerCase().includes(newProvider.newProviderName.toLowerCase())
        );

        if (filteredProviders && filteredProviders.length > 0) {
          setSimilarProviders(filteredProviders);
          toggleSimilarProvidersModal();
        } else submitCallSourceToggle();
        break;
      }
      default:
        break;
    }
  };

  const submitCallSourceToggle = () => {
    setIsSubmit((v) => !v);
  };

  const submitCallSource = async () => {
    submitCallSourceToggle();

    let hopToSubmit: any = {};

    if (step === steps.upstreamVoice) {
      hopToSubmit = {
        hopId: hop.hopId,
        upstreamProviderId: selectedProvider,
        delegatedTo: user.email,
        forwardedCall: forwardedCall,
        stirShakenRequest: generateStirShakenRequestObj(stirShakenInfo),
        contactEmail: selectedProvider === customerEmailProvider?.id ? callSource.contactEmail : '',
        rmdComment:
          !traceback.isInternational &&
          selectedProviderData?.noRmd &&
          user.providerCountry === 'United States'
            ? extraCommentRmd
            : null,
        alternativeEmail: selectedProviderData?.badEmails ? extraContactEmail : null
      };
    } else if (step === steps.callingParty) {
      hopToSubmit = {
        hopId: hop.hopId,
        address: callSource.addressLine1,
        city: callSource.city,
        customer: callSource.businessName,
        contactEmail: callSource.contactEmail,
        contactPhone: callSource.contactPhone,
        contactName: callSource.contactName,
        country: callSource.country,
        state: callSource.region,
        zipCode: callSource.postalCode,
        explanation: callSource.stepsTaken,
        isCallOrig: callOriginatorAndCallingParty,
        delegatedTo: user.email,
        forwardedCall: forwardedCall,
        ipAddress: callSource.ipAddress,
        customerType: callSource.customerType,
        averageTraffic: callSource.averageTraffic,
        actionTaken: callSource.actionTaken,
        refuseReason: callSource.reasonRejection,
        stirShakenRequest: generateStirShakenRequestObj(stirShakenInfo)
      };
      if (disputeInfo.disputeVisible) {
        const commentId = await addCommentObject(
          {
            commentType: 'hop',
            contentText: 'Dispute: ' + disputeInfo.comment,
            relatedObjectId: hop.hopId,
            directedTo: 'admin'
          },
          disputeInfo.attachments
        );
        if (commentId && Number(commentId)) {
          const dispute: HopDispute = {
            hopId: hop.hopId,
            status: DisputeStatus.OpenDispute,
            commentId,
            attributes: disputeInfo.attributes,
            consentGiven: disputeInfo.consentGiven
          };
          hopToSubmit.dispute = dispute;
        }
      }
    } else if (step === steps.callRecordNotProvided) {
      hopToSubmit = {
        hopId: hop.hopId,
        nfnr: callSource.currentCause,
        nfnrReason: callSource.explanation,
        delegatedTo: user.email,
        forwardedCall: forwardedCall
      };
    } else if (step === steps.newProvider) {
      hopToSubmit = {
        hopId: hop.hopId,
        delegatedTo: user.email,
        status: 6,
        newProvider: newProvider,
        stirShakenRequest: generateStirShakenRequestObj(stirShakenInfo)
      };
    }

    if (newTracebackTime) {
      hopToSubmit.newTracebackTime = getApiFormattedDate(newTracebackTime);
      if (hopToSubmit.newTracebackTime === '') {
        next();
      }
    }

    await updateHopObject(hopToSubmit);
    localStorage.setItem('currentCallSource', step);
  };

  const back = () => {
    setCurrentCallSource(steps.default);
    switch (step) {
      case steps.default:
        break;
      case steps.upstreamVoice:
        setSelectedProvider(0);
        setExtraCommentRmd('');
        setExtraContactEmail('');
        setExtraContactEmailError('');
        setExtraCommentRmdError('');
        setNewTracebackTime('');
        setForwardedCall('');
        setStep(steps.default);
        break;
      case steps.callingParty:
        setCallSource(emptyCallSource());
        clearCallSourceErrors();
        setDisputeInfo(emptyDisputeInfo);
        setCallOriginatorAndCallingParty(false);
        setStep(steps.default);
        break;
      case steps.callRecordNotProvided:
        setCallSource(emptyCallSource());
        setStep(steps.default);
        break;
      case steps.rmdSearch:
        setRmdBusinessName('');
        setStep(steps.upstreamVoice);
        break;
      case steps.newProvider:
        setNewProvider(emptyNewHopProvider());
        clearNewProviderErrors();
        setZeroBounceErrors({ contactEmail: '', extraContactEmail: '' });
        setRmdBusinessName('');
        setStirShakenInfo(defaultStirShakenInfo);
        setStirShakenModalErrors(defaultStirShakenErrors);
        setStep(steps.rmdSearch);
        break;
      default:
    }
  };

  const setNewProviderFromRMD = async (rmd: RMDEntry) => {
    if (rmd && rmd.providerName && rmd.providerId) {
      setRmdBusinessName('');
      setSelectedProvider(rmd.providerId);
      setStep(steps.upstreamVoice);
    } else {
      setNewProvider({
        newProviderAddress: rmd.businessAddress || '',
        newProviderCity: rmd.contactCity || '',
        newProviderContactEmail: rmd.contactEmail || '',
        newProviderContactName: rmd.robocallMitigationContactName || '',
        newProviderContactPhone: rmd.contactTelephoneNumber || '',
        newProviderCountry: (rmd.country || '').replace(' of America', '').trim(),
        newProviderStateRegion: rmd.contactState || rmd.contactCity || '',
        newProviderZip: rmd.contactZipCode || '',
        newProviderName: rmd.businessName || '',
        newProviderReason: '',
        newProviderExtraContactEmail: '',
        newRmd: rmd,
        acknowledge: true
      });
      if (!!rmd.contactEmail) await zeroBounceCheckEmail(rmd.contactEmail);
      next();
    }
  };

  const stepComponents: Record<string, JSX.Element> = {
    [steps.default]: (
      <DefaultStep
        selectedCallSource={currentCallSource}
        setSelectedCallSource={setCurrentCallSource}
      />
    ),
    [steps.upstreamVoice]: (
      <UpstreamVoiceStep
        user={user}
        hopDetail={{
          hopId: hop.hopId,
          hopProviderId: hop.hopProvider.providerId,
          hopSequence: hop.hopSequence,
          hopStatus: hop.status,
          isInternational: traceback.isInternational,
          forwardedCall: hop.forwardedCall
        }}
        selectedProvider={selectedProvider}
        handleProviderChange={handleProviderChange}
        selectedProviderData={selectedProviderData}
        extraContactEmail={extraContactEmail}
        extraCommentRmd={extraCommentRmd}
        newTracebackTime={newTracebackTime}
        forwardedCall={forwardedCall}
        extraContactEmailError={extraContactEmailError}
        extraCommentRmdError={extraCommentRmdError}
        setNewTracebackTime={setNewTracebackTime}
        setForwardedCall={setForwardedCall}
        handleCallSourceInputChange={handleCallSourceInputChange}
        newProvider={() => {
          setStep(steps.rmdSearch);
        }}
      />
    ),
    [steps.callingParty]: (
      <CallSourceCustomerForm
        callSource={callSource}
        customerErrors={validateCallSourceErrors(callSource)}
        setCallSource={setCallSource}
        campaignAttribute={campaignAttribute}
        disputeInfo={disputeInfo}
        setDisputeInfo={setDisputeInfo}
        callOriginatorAndCallingParty={callOriginatorAndCallingParty}
        setCallOriginatorAndCallingParty={setCallOriginatorAndCallingParty}
      />
    ),
    [steps.callRecordNotProvided]: (
      <CallRecordStep
        user={user}
        downstreamId={hop.downstreamHopId}
        cause={callSource.currentCause}
        explanation={callSource.explanation}
        handleCauseChange={handleCauseChange}
        handleCallSourceInputChange={handleCallSourceInputChange}
      />
    ),
    [steps.rmdSearch]: (
      <RmdSearcher
        setBusinessName={setRmdBusinessName}
        setRmd={async (rmd: RMDEntry) => await setNewProviderFromRMD(rmd)}
      />
    ),
    [steps.newProvider]: (
      <NewProviderStep
        newProvider={newProvider}
        setNewProvider={setNewProvider}
        errors={validateNewProviderErrors(newProvider)}
        extraErrors={zeroBounceErrors}
        stirShakenInfo={stirShakenInfo}
        stirShakenModalErrors={stirShakenModalErrors}
        setStirShakenInfo={setStirShakenInfo}
        setStirShakenModalErrors={setStirShakenModalErrors}
      />
    )
  };
  return (
    <Fragment>
      <h5 className="p-0 traceback-sub-title mb-3">Who provided you with this call?</h5>
      <Form className={`highlighted-background mb-4 ${callSourceClassName}`}>
        {modalStep === modalsSteps.default ? (
          <>
            <>{stepComponents[step]}</>
            <div className="d-flex justify-content-center">
              {step !== steps.default && (
                <Button className="btn-default telecom-btn" color="light" onClick={back}>
                  Back
                </Button>
              )}
              {step !== steps.rmdSearch && (
                <Button
                  onClick={() => next()}
                  className="telecom-btn ms-2"
                  disabled={
                    (step !== steps.default && step !== steps.newProvider && !canSubmit()) ||
                    (step === steps.default && currentCallSource === steps.default)
                  }
                >
                  {step === steps.callRecordNotProvided ||
                  step === steps.callingParty ||
                  step === steps.upstreamVoice ||
                  step === steps.newProvider
                    ? 'Submit Traceback Response'
                    : 'Next'}
                </Button>
              )}
              {!!rmdBusinessName && step === steps.rmdSearch && (
                <button
                  type="button"
                  className="btn btn-link ms-2"
                  onClick={() => {
                    setNewProvider((v) => ({ ...v, newRmd: undefined }));
                    next();
                  }}
                >
                  Manually enter a provider
                </button>
              )}
            </div>
          </>
        ) : (
          <HopResponseModalsSubmit
            modalStep={modalStep}
            setModalStep={setModalStep}
            setViewStep={setStep}
            customerEmailProvider={customerEmailProvider}
            setSelectedProvider={setSelectedProvider}
            next={next}
            callSource={callSource}
            setCallSource={setCallSource}
            hop={hop}
            traceback={traceback}
            submitCallSourceToggle={submitCallSourceToggle}
            stirShakenInfo={stirShakenInfo}
            stirShakenModalErrors={stirShakenModalErrors}
            setStirShakenInfo={setStirShakenInfo}
            setStirShakenModalErrors={setStirShakenModalErrors}
          />
        )}
      </Form>
      <Modal
        centered
        isOpen={isSubmit}
        className="submit-confirm-modal"
        toggle={submitCallSourceToggle}
      >
        <ModalHeader toggle={submitCallSourceToggle} />
        <ModalBody>
          <h5 className="traceback-sub-title">
            Once you update this hop, its status will no longer be editable. However, you may still
            submit comments.
          </h5>
          <h5 className="traceback-sub-title">Submit this form?</h5>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <Button
            className="btn-default telecom-btn"
            color="light"
            onClick={submitCallSourceToggle}
          >
            Cancel
          </Button>
          <Button
            className="telecom-btn red"
            onClick={() => {
              submitCallSource();
            }}
          >
            Proceed
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        centered
        isOpen={isSimilarProvidersModalOpen}
        className="already-existing-modal"
        toggle={toggleSimilarProvidersModal}
      >
        <ModalHeader toggle={toggleSimilarProvidersModal}>
          Similar provider/s already exist.
        </ModalHeader>
        <ModalBody>
          There is a list of all similar providers (click on any of the listed options in order to
          select the already existing provider):
          <ul style={{ margin: '24px auto' }}>
            {similarProviders &&
              React.Children.toArray(
                similarProviders.map((provider) => (
                  <li
                    className="similar-provider"
                    onClick={() => {
                      setSelectedProvider(provider.id);
                      setStep(steps.upstreamVoice);
                      setNewProvider(emptyNewHopProvider());
                      clearNewProviderErrors();
                      setRmdBusinessName('');
                      setStirShakenInfo(defaultStirShakenInfo);
                      setStirShakenModalErrors(defaultStirShakenErrors);
                      toggleSimilarProvidersModal();
                    }}
                  >
                    {provider.name}
                  </li>
                ))
              )}
          </ul>
        </ModalBody>
        <ModalFooter className="m-auto">
          <div className="d-flex justify-content-center">
            <Button
              className="btn-default telecom-btn"
              color="light"
              onClick={toggleSimilarProvidersModal}
            >
              Cancel
            </Button>
            <Button
              className="telecom-btn ms-2"
              onClick={() => {
                toggleSimilarProvidersModal();
                submitCallSourceToggle();
              }}
            >
              Submit anyway
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    providersData: sm.provider.providerNames || []
  };
};

export default connect(mapStateToProps, null)(CallSourceRow);
