import React, { Fragment } from 'react';
import { Col, Label, Row } from 'reactstrap';
import { userTypes } from '../../../enum/userTypes';
import { NotProvidedReason } from '../../../interfaces/callSource';
import { PersonalInfo } from '../../../interfaces/user';
import { ValidationRules, requiredRule } from '../../../lib/hooks';
import InputError from '../../inputError';
import InputFormGroup from '../../inputFormGroup';

const choices = [
  {
    value: NotProvidedReason.isRecordUn,
    text: 'Record unavailable',
    omit: () => false
  },
  {
    value: NotProvidedReason.isCallOld,
    text: 'Call is too old',
    omit: () => false
  },
  {
    value: NotProvidedReason.isRefusing,
    text: 'We refuse to share this information',
    omit: () => false
  },
  {
    value: NotProvidedReason.isProviderNonRes,
    text: 'Provider is non-responsive',
    omit: (user: PersonalInfo) => user.roleType !== userTypes.Admin
  },
  {
    value: NotProvidedReason.tracebackCancel,
    text: 'Cancel Traceback',
    omit: (user: PersonalInfo) => user.roleType !== userTypes.Admin
  },
  {
    value: NotProvidedReason.imposter,
    text: 'Flag this hop Provider as Imposter',
    omit: (user: PersonalInfo, upstreamId: number) =>
      user.roleType !== userTypes.Admin || upstreamId === 0
  }
];

export const callRecordNotProvidedValidator: ValidationRules = {
  currentCause: [
    {
      rule: requiredRule,
      message: 'The field is required.'
    }
  ],
  explanation: [
    {
      rule: requiredRule,
      message: 'The field is required.'
    }
  ]
};

interface IProps {
  user: PersonalInfo;
  cause: NotProvidedReason;
  setCause: (cause: NotProvidedReason) => void;
  explanation: string;
  setExplanation: (explanation: string) => void;
  errors: { [key: string]: string };
  upstreamId: number;
}

const CallRecordNotProvidedStep: React.FC<IProps> = ({
  user,
  cause,
  setCause,
  explanation,
  setExplanation,
  errors,
  upstreamId
}) => (
  <Fragment>
    <Row className="me-5 ms-5 mt-3" style={{ padding: '0 15px' }}>
      <Label className="telecom-label ms-2">
        Why is No Record being provided?
        <i className="fa fa-asterisk asterisk" />
      </Label>
    </Row>
    {choices.map(
      (v) =>
        !v.omit(user, upstreamId) && (
          <Row className="me-5 ms-5 mt-3" style={{ padding: '0 15px' }}>
            <span className="ms-2">
              <input
                type="radio"
                name="callDestinationTypes"
                value={v.value}
                onChange={() => {
                  setCause(v.value);
                }}
                checked={cause === v.value}
                className="ms-2"
              />
              {v.text}
            </span>
          </Row>
        )
    )}
    {errors.currentCause && (
      <Row className="me-5 ms-5 mt-3" style={{ padding: '0 15px' }}>
        <InputError className="telecom-input-error">{errors.currentCause}</InputError>
      </Row>
    )}
    <Row className="me-5 ms-5">
      <Col lg="10">
        <Label className="telecom-label ms-2 mt-3">
          Explanation
          <i className="fa fa-asterisk asterisk" />
        </Label>
        <InputFormGroup
          isReadonly={false}
          isTextarea
          inputName="explanation"
          inputId="explanation"
          inputClassName="input-hop"
          inputValue={explanation}
          inputOnChange={(e) => {
            if (e !== null && e.currentTarget) {
              const { value } = e.currentTarget;
              setExplanation(value);
            }
          }}
          inputPlaceholder="Please leave a response explaining why you are either unable to locate the call record or are unwilling to share this information.
                                      If no match found using Calling Number, please try searching for the Called Number only."
          inputAutoComplete="off"
        />
        {errors.explanation && (
          <InputError className="telecom-input-error">{errors.explanation}</InputError>
        )}
      </Col>
    </Row>
  </Fragment>
);

export default CallRecordNotProvidedStep;
