import React, { Dispatch, FC, Fragment, SetStateAction } from 'react';
import { RegionDropdown } from 'react-country-region-selector';
import { Label } from 'reactstrap';
import { NewHopProvider } from '../../../interfaces/hop';
import { ValidationRules, regexRule, requiredRule } from '../../../lib/hooks';
import { validEmailRegex } from '../../../lib/regex';
import { getValidateEmailApi } from '../../../redux/messaging/apiCalls';
import CountryDropdown, { getCountryCodeByName } from '../../HopDetail/CountryDropdown';
import InputError from '../../inputError';
import InputFormGroup from '../../inputFormGroup';
import TimezoneDropdown from '../../TimezoneDropdown';
import { CountryName } from 'country-region-data';
import StirShakenBody from '../../StirShakenBody';
import { StirShakenInfo, StirShakenModalErrors } from '../../../interfaces/stirShaken';

interface IProps {
  newProvider: NewHopProvider;
  setNewProvider: Dispatch<SetStateAction<NewHopProvider>>;
  errors: { [key: string]: string };
  extraErrors: ZerobounceErrors;
  stirShakenInfo?: StirShakenInfo;
  stirShakenModalErrors?: StirShakenModalErrors;
  setStirShakenInfo?: Dispatch<React.SetStateAction<StirShakenInfo>>;
  setStirShakenModalErrors?: Dispatch<React.SetStateAction<StirShakenModalErrors>>;
}

export interface ZerobounceErrors {
  contactEmail: string;
  extraContactEmail: string;
}

const checkZeroBounceError = (status: string) => {
  switch (status) {
    case 'BOUNCE':
      return 'SES blocked';
    case 'COMPLAINT':
      return 'SES blocked';
    case 'does_not_accept_mail':
      return 'domain error';
    case 'failed_syntax_check':
      return 'fail RFC syntax protocol';
    case 'possible_typo':
      return 'misspelled popular domain';
    case 'mailbox_not_found':
      return 'email do not exist';
    case 'no_dns_entries':
      return 'incomplete DNS Record';
    case 'mailbox_quota_exceeded':
      return 'exceeded space quota';
    case 'unroutable_ip_address':
      return 'un-routable IP address';
    case 'global_suppression':
      return 'email found in many popular global suppression lists';
    case 'possible_trap':
      return 'email contain keywords that might correlate to possible spam';
    case 'role_based':
      return 'email belong to a position or a group of people';
    case 'disposable':
      return 'temporary email';
    case 'toxic':
      return 'abuse, spam, or bot created email';
    case 'role_based_catch_all':
      return 'email belong to a catch_all domain.';
    case 'mx_forward':
      return 'domain error';
    case 'wrongFormat':
      return 'invalid email';
    default:
      return 'unknown error';
  }
};

export const GetZeroBounceErrors = async (contactEmail: string, withRmd?: boolean) => {
  const prepareError = (error: string | any) =>
    'The email has an issue - ' +
    error +
    '.' +
    (withRmd ? ' Please provide a different email' : ' Please submit a valid email');
  try {
    const { data } = await getValidateEmailApi(contactEmail);
    if (data && (data.status === 'invalid' || data.aws_reason)) {
      return prepareError(
        checkZeroBounceError(data.sub_status ? data.sub_status : data.aws_reason)
      );
    }
    return '';
  } catch (error: any) {
    return prepareError(error);
  }
};

export const newProviderValidator: ValidationRules = {
  newProviderName: [
    {
      rule: requiredRule,
      message: 'Please provide a Business Name !'
    }
  ],
  newProviderContactName: [
    {
      rule: requiredRule,
      message: 'Please provide a Contact Name !'
    }
  ],
  newProviderCountry: [
    {
      rule: requiredRule,
      message: 'Please provide a country !'
    }
  ],
  newProviderContactEmail: [
    {
      rule: requiredRule,
      message: 'Please provide an email !'
    },
    {
      rule: regexRule(validEmailRegex),
      message: 'This email address is not valid !'
    }
  ],
  newProviderExtraContactEmail: [
    {
      rule: requiredRule,
      message: 'Please provide an email !'
    },
    {
      rule: regexRule(validEmailRegex),
      message: 'This email address is not valid !'
    }
  ],
  acknowledge: [
    {
      rule: requiredRule,
      message: 'You must acknowledge in order to submit !'
    }
  ]
};

const NewProviderStep: FC<IProps> = ({
  newProvider,
  setNewProvider,
  errors,
  extraErrors,
  stirShakenInfo,
  stirShakenModalErrors,
  setStirShakenInfo,
  setStirShakenModalErrors
}) => {
  const setCountryFunction = (label: string) => {
    setNewProvider((v): NewHopProvider => ({ ...v, newProviderCountry: label }));
  };
  const setTimezoneFunction = (label: string) => {
    setNewProvider((v): NewHopProvider => ({ ...v, newProviderTimeZone: label }));
  };
  const handleInputChange = (key: string, e: any) => {
    switch (key) {
      case 'businessName': {
        const value = e.target.value;
        setNewProvider((v): NewHopProvider => ({ ...v, newProviderName: value }));
        break;
      }
      case 'contactName': {
        const value = e.target.value;
        setNewProvider((v): NewHopProvider => ({ ...v, newProviderContactName: value }));
        break;
      }
      case 'addressLine1': {
        const value = e.target.value;
        setNewProvider((v): NewHopProvider => ({ ...v, newProviderAddress: value }));
        break;
      }
      case 'contactEmail': {
        const value = e.target.value;
        setNewProvider((v): NewHopProvider => ({ ...v, newProviderContactEmail: value }));
        break;
      }
      case 'extraContactEmail': {
        const value = e.target.value;
        setNewProvider((v): NewHopProvider => ({ ...v, newProviderExtraContactEmail: value }));
        break;
      }
      case 'city': {
        const value = e.target.value;
        setNewProvider((v): NewHopProvider => ({ ...v, newProviderCity: value }));
        break;
      }
      case 'contactPhone': {
        const value = e.target.value;
        setNewProvider((v): NewHopProvider => ({ ...v, newProviderContactPhone: value }));
        break;
      }
      case 'zipCode': {
        const value = e.target.value;
        setNewProvider((v): NewHopProvider => ({ ...v, newProviderZip: value }));
        break;
      }
      case 'comment': {
        const value = e.target.value;
        setNewProvider((v): NewHopProvider => ({ ...v, newProviderReason: value }));
        break;
      }
      case 'region':
        const value = e;
        setNewProvider((v): NewHopProvider => ({ ...v, newProviderStateRegion: value }));
        break;
      case 'acknowledge':
        setNewProvider((v): NewHopProvider => ({ ...v, acknowledge: !v.acknowledge }));
        break;
    }
  };
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-6">
          <Label className="telecom-label">
            Provider Business Name <i className="fa fa-asterisk asterisk" />
          </Label>
          <InputFormGroup
            isReadonly={!!newProvider.newRmd && !!newProvider.newRmd?.businessName}
            inputName="p-businessName"
            inputId="p-businessName"
            inputClassName="input-hop"
            inputValue={newProvider.newProviderName}
            inputPlaceholder=""
            inputOnChange={(e) => handleInputChange('businessName', e)}
            inputAutoComplete="off"
            errorMessage={errors.newProviderName}
          />
        </div>
        <div className="col-md-6">
          <Label className="telecom-label">
            Individual Contact Name <i className="fa fa-asterisk asterisk" />
          </Label>
          <InputFormGroup
            isReadonly={!!newProvider.newRmd && !!newProvider.newRmd?.robocallMitigationContactName}
            inputName="p-contactName"
            inputId="p-contactName"
            inputClassName="input-hop"
            inputValue={newProvider.newProviderContactName}
            inputPlaceholder=""
            inputOnChange={(e) => handleInputChange('contactName', e)}
            inputAutoComplete="off"
            errorMessage={errors.newProviderContactName}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Label className="telecom-label">
            Country
            <i className="fa fa-asterisk asterisk" />
          </Label>
          {!newProvider.newRmd || !newProvider.newProviderCountry ? (
            <CountryDropdown
              className={'country-dropdown'}
              name={'hopdetail-country-dropdown'}
              value={newProvider.newProviderCountry}
              setCountry={setCountryFunction}
              extraOption={false}
            />
          ) : (
            <p className="telecom-text mb-0 mt-1 ms-2">{newProvider.newProviderCountry}</p>
          )}
          {errors.newProviderCountry && (
            <InputError className="telecom-input-error">{errors.newProviderCountry}</InputError>
          )}
        </div>
        <div className="col-md-6">
          <Label className="telecom-label">Address</Label>
          <InputFormGroup
            isReadonly={!!newProvider.newRmd && !!newProvider.newRmd.businessAddress}
            inputName="p-addressLine1"
            inputId="p-addressLine1"
            inputClassName="input-hop"
            inputValue={newProvider.newProviderAddress}
            inputPlaceholder=""
            inputOnChange={(e) => handleInputChange('addressLine1', e)}
            inputAutoComplete="off"
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-6">
          <Label className="telecom-label">
            Provider Contact Email <i className="fa fa-asterisk asterisk" />
          </Label>
          <InputFormGroup
            isReadonly={!!newProvider.newRmd && !!newProvider.newRmd?.contactEmail}
            inputName="p-contactEmail"
            inputId="p-contactEmail"
            inputClassName="input-hop"
            inputValue={newProvider.newProviderContactEmail}
            inputPlaceholder=""
            inputOnChange={(e) => handleInputChange('contactEmail', e)}
            inputAutoComplete="off"
            errorMessage={errors.newProviderContactEmail || extraErrors.contactEmail}
          />
        </div>
        <div className="col-md-6">
          <Label className="telecom-label">City</Label>
          <InputFormGroup
            isReadonly={!!newProvider.newRmd && !!newProvider.newRmd?.contactCity}
            inputName="p-city"
            inputId="p-city"
            inputClassName="input-hop"
            inputValue={newProvider.newProviderCity}
            inputPlaceholder=""
            inputOnChange={(e) => handleInputChange('city', e)}
            inputAutoComplete="off"
          />
        </div>
      </div>
      {!!newProvider.newRmd &&
        !!newProvider.newRmd?.contactEmail &&
        !!(errors.newProviderContactEmail || extraErrors.contactEmail) && (
          <div className="row mb-3">
            <div className="col-md-6">
              <Label className="telecom-label">
                Extra Provider Contact Email <i className="fa fa-asterisk asterisk" />
              </Label>
              <InputFormGroup
                inputName="p-contactEmail"
                inputId="p-contactEmail"
                inputClassName="input-hop"
                inputValue={newProvider.newProviderExtraContactEmail}
                inputPlaceholder=""
                inputOnChange={(e) => handleInputChange('extraContactEmail', e)}
                inputAutoComplete="off"
                errorMessage={errors.newProviderExtraContactEmail || extraErrors.extraContactEmail}
              />
            </div>
          </div>
        )}
      <div className="row">
        <div className="col-md-6">
          <Label className="telecom-label">Provider Contact Phone</Label>
          <InputFormGroup
            isReadonly={!!newProvider.newRmd && !!newProvider.newRmd?.contactTelephoneNumber}
            inputName="p-contactPhone"
            inputId="p-contactPhone"
            inputClassName="input-hop"
            inputValue={newProvider.newProviderContactPhone}
            inputPlaceholder=""
            inputOnChange={(e) => handleInputChange('contactPhone', e)}
            inputAutoComplete="off"
          />
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-5 pe-1">
              <Label className="telecom-label">State / Region</Label>
              <RegionDropdown
                disabled={
                  !!newProvider.newRmd &&
                  newProvider.newRmd?.contactCity === null &&
                  newProvider.newProviderCity === null
                }
                country={newProvider.newProviderCountry}
                value={newProvider.newProviderStateRegion}
                className={'region-dropdown'}
                name={'hopdetail-region-dropdown'}
                onChange={(e) => handleInputChange('region', e)}
              />
            </div>
            <div className="col-md-5 ps-0">
              <Label className="telecom-label">Zip / Postal Code</Label>
              <InputFormGroup
                isReadonly={!!newProvider.newRmd && !!newProvider.newRmd?.contactZipCode}
                inputName="p-zipCode"
                inputId="p-zipCode"
                inputClassName="input-hop"
                inputValue={newProvider.newProviderZip}
                inputPlaceholder=""
                inputOnChange={(e) => handleInputChange('zipCode', e)}
                inputAutoComplete="off"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4">
          <Label className="telecom-label">Time Zone</Label>
          <TimezoneDropdown
            countryCode={getCountryCodeByName(newProvider.newProviderCountry as CountryName)}
            value={newProvider.newProviderTimeZone || ''}
            setTimezone={setTimezoneFunction}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <Label className="telecom-label">
            Please provide ITG with any additional information we may need to know about this New
            Provider
          </Label>
          <InputFormGroup
            isTextarea
            inputName="p-comment"
            inputId="p-comment"
            inputClassName="input-hop"
            inputValue={newProvider.newProviderReason}
            inputOnChange={(e) => handleInputChange('comment', e)}
            inputPlaceholder=""
            inputAutoComplete="off"
          />
        </div>
      </div>
      {stirShakenInfo && stirShakenModalErrors && setStirShakenInfo && setStirShakenModalErrors && (
        <div className="col">
          <StirShakenBody
            setStirShakenInfo={setStirShakenInfo}
            stirShakenInfo={stirShakenInfo}
            stirShakenModalErrors={stirShakenModalErrors}
            setStirShakenModalErrors={setStirShakenModalErrors}
          />
        </div>
      )}
      {!newProvider.newRmd ? (
        <>
          <div className="d-flex justify-content-center pt-3">
            <input
              checked={newProvider.acknowledge}
              onChange={(e) => handleInputChange('acknowledge', e)}
              type="checkbox"
              className="ms-3"
              style={{ height: 20, width: 20 }}
            />
            <Label className="telecom-label">
              I am aware and acknowledge that this provider is not in the FCC's Robocall Mitigation
              Database.
            </Label>
          </div>
          <div className="d-flex justify-content-center py-2">
            <Label className="telecom-label">
              * Please contact{' '}
              <a
                style={{ cursor: 'pointer', color: 'blue' }}
                onClick={() => (window.location.href = 'mailto:support@tracebacks.org')}
              >
                support@tracebacks.org{' '}
              </a>
              with a link to the provider's filing if you believe the provider is in the Robocall
              Mitigation Database
            </Label>
          </div>
        </>
      ) : null}
      {errors.acknowledge && (
        <InputError className="telecom-input-error text-center pb-5">
          {errors.acknowledge}
        </InputError>
      )}
    </Fragment>
  );
};

export default NewProviderStep;
