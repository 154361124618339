import { Pagination } from 'reactstrap';
import { ChartFilterElements } from '../../interfaces/charts';
import {
  StirShakenProviderNotInCallPathRequest,
  StirShakenSearchRequest,
  StirShakenSigner
} from '../../interfaces/stirShaken';
import { ApiRequest, buildParams } from '../../lib/apiRequest';

export const getStirShakensApiCall = async (params: Pagination, req: StirShakenSearchRequest) =>
  ApiRequest.request({
    method: 'POST',
    url: `stir_shaken/search?` + buildParams(params),
    data: req
  });

export const updateStirShakenApiCall = async (payload: StirShakenSigner) =>
  ApiRequest.request({
    method: 'POST',
    url: `stir_shaken/updateSigners`,
    data: payload
  });

export const getAttestationStatsApiCall = async (filterElements: ChartFilterElements) =>
  ApiRequest.request({
    method: 'POST',
    url: `stir_shaken/attestationStats`,
    data: filterElements
  });

export const getOriginStatsApiCall = async (filterElements: ChartFilterElements) =>
  ApiRequest.request({
    method: 'POST',
    url: `stir_shaken/originStats`,
    data: filterElements
  });

export const getStirShakensIssuesApiCall = async (
  params: Pagination,
  data: StirShakenProviderNotInCallPathRequest
) =>
  ApiRequest.request({
    method: 'POST',
    url: `stir_shaken/providersNotInPath?` + buildParams(params),
    data
  });

export const getStirShakensProviderIssuesApiCall = async (
  data: StirShakenProviderNotInCallPathRequest
) =>
  ApiRequest.request({
    method: 'POST',
    url: `stir_shaken/providerNotInPathTracebacks`,
    data
  });
