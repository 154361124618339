import React, { FC } from 'react';
import { Label } from 'reactstrap';
export enum steps {
  default = 'default',
  upstreamVoice = 'upstreamVoice',
  callingParty = 'callingParty',
  callRecordNotProvided = 'callRecordNotProvided',
  rmdSearch = 'rmdSearch',
  newProvider = 'newProvider'
}
const choices = [
  {
    text: 'Upstream Voice Service Provider',
    value: steps.upstreamVoice
  },
  {
    text: 'Calling party / end user / subscriber',
    value: steps.callingParty
  },
  {
    text: 'Call record not provided',
    value: steps.callRecordNotProvided
  }
];
interface DefaultProps {
  selectedCallSource: steps;
  setSelectedCallSource: (s: steps) => void;
}

const DefaultStep: FC<DefaultProps> = ({ selectedCallSource, setSelectedCallSource }) => {
  return (
    <>
      <Label className="telecom-label extra p-0">Call Source</Label>
      <Label className="telecom-label p-0">
        I received this call from
        <i className="fa fa-asterisk asterisk" />
      </Label>
      <div>
        {choices.map((v) => (
          <div key={`defaultStepKey-${v.value}`}>
            <input
              type="radio"
              name={v.value}
              onChange={() => {
                setSelectedCallSource(v.value);
              }}
              checked={v.value == selectedCallSource}
            />{' '}
            <label key={v.value}>{v.text}</label>
          </div>
        ))}
      </div>
    </>
  );
};

export default DefaultStep;
