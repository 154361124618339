export interface Pagination {
  order: string;
  page: number;
  pageSize: number;
  sort: string;
}

export function defaultPagination(): Pagination {
  return {
    sort: '',
    order: 'asc',
    page: 1,
    pageSize: 10
  };
}
export function defaultPaginationStirShakenSigners(): Pagination {
  return {
    sort: 'signerOrganization',
    order: 'asc',
    page: 1,
    pageSize: 10
  };
}
export function defaultPaginationNotification(): Pagination {
  return {
    sort: 'create_date',
    order: 'desc',
    page: 1,
    pageSize: 10
  };
}

const possibleOrders = ['asc', 'desc'];
export const paginationFromQueryParams = (
  params: URLSearchParams,
  allowedSort: string[],
  defaultSort: string = '',
  defaultOrder: 'asc' | 'desc' = 'asc'
): Pagination => {
  const order = params.get('order') || '';
  const page = Math.max(1, Number(params.get('page')));
  const pageSize = Math.min(500, Math.max(10, Number(params.get('pageSize'))));
  const sort = params.get('sort') || defaultSort;
  return {
    order: possibleOrders.some((v) => v == order) ? order : defaultOrder,
    page,
    pageSize,
    sort: allowedSort.some((v) => v === sort) ? sort : defaultSort
  };
};

export const paginationToQueryParams = (p: Pagination, params: URLSearchParams) => {
  params.set('order', p.order);
  params.set('page', p.page.toString());
  params.set('pageSize', p.pageSize.toString());
  params.set('sort', p.sort);
  return params;
};
