import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Row } from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import CampaignsSelect from '../components/CampaignsSelect';
import CustomDataTable from '../components/CustomDataTable';
import { SelectOption } from '../components/CustomSelect';
import GeneralDatePicker from '../components/GeneralDatePicker';
import FollowProviderCheckbox from '../components/shared/FollowProviderCheckbox';
import LabelSelect from '../components/shared/LabelSelect';
import LanguageSelect from '../components/shared/LanguageSelect';
import Legend from '../components/shared/Legend';
import RegionSelect from '../components/shared/RegionSelect';
import TracebacksExpandedComponent from '../components/traceback/TracebacksExpandedComponent';
import WeeklyStatistics from '../components/weeklyStatistics';
import { userTypes } from '../enum/userTypes';
import { Campaign } from '../interfaces/campaign';
import { Pagination, defaultPagination } from '../interfaces/pagination';
import { Traceback } from '../interfaces/traceback';
import { PersonalInfo } from '../interfaces/user';
import { FilterElement, getFilter } from '../lib/FilterElement';
import { tracebacksGovPageColumns } from '../lib/dataTableUtils/tracebacksGovPageColumns';
import { tracebacksPageColumns } from '../lib/dataTableUtils/tracebacksPageColumns';
import {
  decodeQueryParams,
  encodeQueryParams,
  getFromLocalStorage,
  saveToLocalStorage
} from '../lib/history-utils';
import { languageListWithNonEnglish } from '../lib/languageSelect';
import { getRegionLabel } from '../lib/regionSelectUtils';
import { getCampaignList } from '../redux/campaign/thunks';
import { stateMappings } from '../redux/stateMappings';
import { getTracebackApiCall } from '../redux/traceback/apiCalls';
import {
  clearTracebacksList,
  getTracebackList,
  getTracebackStats,
  getTracebacksCSV
} from '../redux/traceback/thunks';
import { getCampaignLanguageFilter, handleLanguage } from './Campaigns';

const PAGENAME = 'tracebacks';

interface IProps {
  user: PersonalInfo;
  tracebacks: Traceback[];
  tbStats: object;
  getTracebackList: Function;
  getCampaignList: Function;
  getTracebackStats: Function;
  clearTracebacksList: Function;
  getTracebacksCSV: Function;
  paginationTotalRows: number;
  campaignList: Campaign[];
}

const setFiltersFromHistory: any = (savedSearchDetails: any) => {
  let filterableArray = [];
  const { filterElements } = savedSearchDetails;
  let userInput;
  let campaignId = 0;
  let endDate: string | undefined;
  let status: any[] = [];
  let startDate: string | undefined;
  let termRegion = 0;
  let labelsId: any[] = [];
  let language: any[] = [];

  if (!filterElements) {
    return {};
  }
  if (!Array.isArray(filterElements.conditionals)) {
    filterableArray.push(filterElements);
  } else {
    filterableArray = filterElements.conditionals;
  }

  filterableArray.forEach((condition: FilterElement) => {
    const { comparator, conditionals, name, value } = condition;
    switch (name) {
      case 'campaignId':
        campaignId = Number(value);
        break;
      case 'status':
        status.push(Number(value));
        break;
      case 'termRegion':
        termRegion = Number(value);
        break;
      case 'updateDate':
        switch (comparator) {
          case 'GT':
            startDate = value;
            break;
          case 'LT':
            endDate = value;
            break;
          default:
            break;
        }
        break;
      case 'startDate':
        startDate = value;
        break;
      case 'endDate':
        endDate = value;
        break;
      case 'labelId':
        const labelsValue = value ? value.split(',') : [];
        labelsId = labelsValue.map((label: any) => Number(label));
        break;
      case 'language':
        language = handleLanguage(language, condition);
        break;

      // This defined case exists because the existing DS does not assign a name value
      // to how the code structures multiple filters of the same "type"
      case undefined:
        if (Array.isArray(conditionals)) {
          conditionals.forEach((condition: FilterElement) => {
            const { value } = condition;
            if (condition.name === 'status') {
              status.push(Number(condition.value));
            }
            if (
              condition.name === 'startDate' ||
              (condition.name === 'updateDate' && condition.comparator === 'GT')
            ) {
              startDate = value;
            }
            if (
              condition.name === 'endDate' ||
              (condition.name === 'updateDate' && condition.comparator === 'LT')
            ) {
              endDate = value;
            }
            if (condition.name === 'campaignId') {
              campaignId = Number(value);
            }
            if (condition.name === 'labelId') {
              const labelsValue = value ? value.split(',') : [];
              labelsId = labelsValue.map((label: any) => Number(label));
            }
            if (condition.name === 'language') {
              language = handleLanguage(language, condition);
            }
          });
        }
        break;
      default:
        break;
    }
  });
  return { campaignId, endDate, startDate, status, termRegion, userInput, labelsId, language };
};

const Tracebacks: React.FC<IProps> = ({
  tracebacks,
  tbStats,
  getTracebackList,
  getCampaignList,
  getTracebackStats,
  clearTracebacksList,
  getTracebacksCSV,
  paginationTotalRows,
  campaignList,
  user
}) => {
  const [searchParams] = useSearchParams();
  const campaignIdNum = useMemo(() => Number(searchParams.get('campaignId')), [searchParams]);
  const statusNum = useMemo(() => searchParams.get('status'), [searchParams]);

  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const searchParams2 = search || getFromLocalStorage(PAGENAME) || '';
  const [savedSearchDetails, setSavedSearchDetails] = useState(
    decodeQueryParams(PAGENAME, searchParams2)
  );

  const filtersFromHistory = setFiltersFromHistory(savedSearchDetails);

  const [terminalRegion, setTerminalRegion] = useState<string>(filtersFromHistory.termRegion);
  const [paginationParams, setPaginationParams] = useState(
    savedSearchDetails.paginationParams || {
      ...defaultPagination(),
      sort: 'update_date',
      order: 'desc'
    }
  );
  const [startDate, setStartDate] = useState<string>(filtersFromHistory.startDate || '');
  const [endDate, setEndDate] = useState<string>(filtersFromHistory.endDate || '');
  const [status, setStatus] = useState(filtersFromHistory.status || (statusNum ? [statusNum] : []));
  const [campaignId, setCampaignId] = useState(
    filtersFromHistory.campaignId || campaignIdNum || null
  );
  const [campaignIds, setCampaignIds] = useState<number[]>([]);
  const [campaignMap, setCampaignMap] = useState(new Map());
  const [filterElements, setFilterElements] = useState<FilterElement>({});
  const [userInput, setUserInput] = useState('');
  const [csdInput, setCsdInput] = useState('');
  const [stirShakenInfo, setStirshakenInfo] = useState('');
  const [tracebackNumber, setTracebackNumber] = useState('');
  const [tracebackNumberError, setTracebackNumberError] = useState(false);
  const [chosenLabels, setChosenLabels] = useState<number[] | undefined>(
    localStorage.getItem('campaignLabel')
      ? [Number(localStorage.getItem('campaignLabel'))]
      : filtersFromHistory.labelsId || undefined
  );
  const [chosenLanguages, setChosenLanguages] = useState<string[]>(
    filtersFromHistory.language || []
  );
  const [isInternationalFilter, setIsInternationalFilter] = useState(false);

  const updateFilter = () => {
    setFilterElements(
      user.roleType === userTypes.GovUser
        ? getCampaignLanguageFilter(
            getFilter({
              status,
              campaignId,
              campaignIds,
              terminalRegion,
              startDate,
              endDate,
              csdInput,
              labelId: chosenLabels,
              stirShakenInfo,
              isInternational: isInternationalFilter
            }),
            chosenLanguages
          )
        : getFilter({
            status,
            campaignId,
            campaignIds,
            terminalRegion,
            startDate,
            endDate,
            csdInput,
            labelId: chosenLabels,
            stirShakenInfo,
            isInternational: isInternationalFilter
          })
    );
  };

  const updatePagination = (params: Pagination) => {
    setPaginationParams(params);
  };

  const getData = () => {
    const searchParams = encodeQueryParams(
      PAGENAME,
      {
        paginationParams,
        filterElements
      },
      { campaignId: campaignId, statusNum: status }
    );

    saveToLocalStorage(PAGENAME, searchParams);
    saveToLocalStorage('campaign', userInput);
    getTracebackList(paginationParams, filterElements);
    window.scrollTo(0, 0);
  };

  const fillCampaignArray = (campaignArr: Campaign[]) => {
    let newCampaignSet = new Map();
    for (let i = 0; i < campaignArr.length; i++) {
      newCampaignSet.set(campaignArr[i].name, campaignArr[i].campaignId);
    }
    setCampaignMap(newCampaignSet);
  };

  const handleSelectLabels = (value: any) => {
    if (localStorage.getItem('campaignLabel')) localStorage.removeItem('campaignLabel');
    setChosenLabels(value ? value.map((item: SelectOption) => item.value) : []);
  };

  useEffect(() => {
    if (user.roleType === userTypes.Admin) {
      getTracebackStats();
      getCampaignList();
    }
  }, []);

  useEffect(() => {
    if (campaignList && campaignList.length) {
      fillCampaignArray(campaignList);
    }
  }, [campaignList]);

  useEffect(() => {
    updateFilter();
  }, [
    startDate,
    endDate,
    terminalRegion,
    campaignId,
    campaignIds,
    status,
    chosenLabels,
    chosenLanguages,
    isInternationalFilter
  ]);

  useEffect(() => {
    getData();
  }, [paginationParams, filterElements]);

  const handleGoToTraceback = async () => {
    const tbId = Number(tracebackNumber);
    if (isNaN(tbId)) {
      setTracebackNumberError(true);
    }
    try {
      const result = await getTracebackApiCall(tbId);
      if (result.status === 200) {
        window.open(`/tracebacks/traceback/${tracebackNumber}`, '_blank');
        setTracebackNumber('');
        setTracebackNumberError(false);
      } else {
        setTracebackNumberError(true);
      }
    } catch (err) {
      setTracebackNumberError(true);
    }
  };

  const handleSearchCsdInput = () => {
    updateFilter();
  };

  const handleTracebackNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTracebackNumber(e.target.value);
  };

  const handleCsdInput = (userOption: string) => {
    if (userOption) {
      setCsdInput(userOption);
    } else {
      setCsdInput('');
    }
  };

  const handleStirShakenInfo = (userOption: string) => {
    if (userOption) {
      setStirshakenInfo(userOption);
    } else {
      setStirshakenInfo('');
    }
  };

  const handleCheck = (e: any, key: string) => {
    switch (key) {
      case 'termRegion':
        if (e) {
          setTerminalRegion(e.value);
          setUserInput('');
          setCampaignIds([]);
        } else {
          setTerminalRegion('');
        }
        break;
      case 'campaignId':
        if (!e) {
          setCampaignId(0);
        } else if (typeof e === 'object') {
          if ('label' in e) {
            setUserInput('');
            setCampaignId(e.value);
            setCampaignIds([]);
          } else {
            setCampaignIds(Array.from(e));
          }
        }
        break;
      case 'status':
        const newStatus = status.includes(Number(e.target.value))
          ? status.filter((item: any) => item !== Number(e.target.value))
          : [...status, Number(e.target.value)];
        setStatus(newStatus);
        break;
    }
  };

  const handleSelectLanguages = (value: any) => {
    setChosenLanguages(value ? value.map((item: SelectOption) => item.label) : []);
  };

  const redirectInput = (userOption: string) => {
    setUserInput(userOption);
    let valueBag = new Set();
    if (userOption) {
      for (let key of Array.from(campaignMap.keys())) {
        if (key.toLowerCase().includes(userOption.toLowerCase())) {
          valueBag.add(campaignMap.get(key));
        }
      }
      if (valueBag.size == 0) {
        clearTracebacksList();
      } else {
        handleCheck(valueBag, 'campaignId');
        setCampaignId(0);
        setTerminalRegion('');
        setCsdInput('');
        setStirshakenInfo('');
      }
    } else {
      setCampaignIds([]);
      setCampaignId(0);
    }
    return;
  };

  const clearFilters = () => {
    const searchParams = search || getFromLocalStorage(PAGENAME) || '';
    setChosenLabels(undefined);
    setChosenLanguages([]);
    setStatus([]);
    setTerminalRegion('');
    setCampaignId(0);
    setCampaignIds([]);
    setFilterElements({});
    setStartDate('');
    setEndDate('');
    setSavedSearchDetails(decodeQueryParams(PAGENAME, searchParams));
    setPaginationParams({
      ...defaultPagination(),
      sort: 'update_date',
      order: 'desc'
    });
    setCsdInput('');
    setStirshakenInfo('');
    setUserInput('');

    if (search) {
      navigate(pathname);
    }
  };

  return (
    <Fragment key="test">
      {user.roleType === userTypes.Admin ? (
        <Breadcrumb
          importTracebackButton
          title="tracebacks"
          className="table-breadcrumbs"
          btnText="Add Traceback"
          centerContent={<WeeklyStatistics tbStats={tbStats} />}
        />
      ) : (
        <FollowProviderCheckbox
          className="d-flex  providerSummary-title-container  justify-content-between"
          labelId={chosenLabels && chosenLabels.length === 1 ? chosenLabels[0] : undefined}
        >
          <Breadcrumb title="tracebacks" className="table-breadcrumbs" />
        </FollowProviderCheckbox>
      )}
      <Card className="table-card hide-border mb-1">
        <CardHeader className="card-header-traceback">
          <Row>
            <GeneralDatePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              placeholderTextStart="&#xf133; startDate"
              placeholderTextEnd="&#xf133; endDate"
              containerClassname="col-md-2"
              clearButtonTitle={'Clear'}
              isClearable
            />
            {user.roleType === userTypes.Admin && (
              <Fragment>
                <Col xl="2" lg="3">
                  <RegionSelect
                    onChange={(option) => handleCheck(option, 'termRegion')}
                    value={terminalRegion}
                    placeholder={getRegionLabel(terminalRegion)}
                    typeLabel="Called Party State"
                    isClearable={!!terminalRegion}
                  />
                </Col>
                <Col xl="2" lg="3">
                  <CampaignsSelect
                    value={campaignId}
                    onChange={(option) => handleCheck(option, 'campaignId')}
                    isSearchable={true}
                    addLink={false}
                    placeholder="All Campaigns"
                    addAllItem
                    isClearable={campaignId > 0 && true}
                  />
                </Col>
                <Col xl="2" lg="4" sm="4" className="padding-top-conditional">
                  <DebounceInput
                    type={'text'}
                    className="campaign-search"
                    placeholder={'Search Campaign by Partial Text'}
                    onChange={(event) => redirectInput(event.target.value)}
                    value={userInput}
                    minLength={2}
                    debounceTimeout={1000}
                  />
                </Col>
              </Fragment>
            )}
            {user.roleType !== userTypes.Admin && (
              <Fragment>
                <div className="col-2">
                  <LabelSelect
                    isMulti
                    addAllItem
                    value={chosenLabels}
                    onChange={handleSelectLabels}
                    numberDisplayed={2}
                    placeholder="Select Campaign…"
                    typeLabel="All Campaigns"
                  />
                </div>
                <div className="col-2">
                  <LanguageSelect
                    isMulti
                    addAllItem
                    value={chosenLanguages.map(
                      (v) =>
                        languageListWithNonEnglish.find((i) => i.label === v) || {
                          value: -1,
                          label: 'Non-English'
                        }
                    )}
                    onChange={handleSelectLanguages}
                    placeholder="Select Language…"
                    numberDisplayed={2}
                    isSearchable
                    addNonEnglish
                    onlySelectedLanguages
                  />
                </div>
              </Fragment>
            )}
          </Row>

          <div className="flex-row align-items-center my-1">
            {user.roleType === userTypes.Admin && (
              <DebounceInput
                type={'text'}
                className="csd-search"
                placeholder={'Search Traceback by Call Source'}
                onChange={(event) => handleCsdInput(event.target.value)}
                value={csdInput}
                minLength={2}
                debounceTimeout={1000}
              />
            )}
            <DebounceInput
              type={'text'}
              className={`csd-search ${user.roleType === userTypes.Admin ? 'ms-2' : ''}`}
              placeholder={'Search Traceback by Call Signer'}
              onChange={(event) => handleStirShakenInfo(event.target.value)}
              value={stirShakenInfo}
              minLength={2}
              debounceTimeout={1000}
            />
            <a onClick={handleSearchCsdInput} target="_blank" className="btn btn-link ms-2">
              Go
            </a>
          </div>

          {user.roleType === userTypes.Admin && (
            <Row>
              <Col>
                <div className="my-1 div-left flex-row align-items-center">
                  <Input
                    value={tracebackNumber}
                    onChange={handleTracebackNumberChange}
                    className="csd-search"
                    placeholder="Go to Traceback #"
                  />
                  <a onClick={handleGoToTraceback} target="_blank" className="btn btn-link me-2">
                    Go
                  </a>
                </div>
                {tracebackNumberError && <div className="text-red">Invalid Traceback #</div>}
              </Col>
            </Row>
          )}

          <Row>
            <Col lg="6">
              <div className="d-flex flex-wrap media-query-wrap">
                {user.roleType === userTypes.Admin && (
                  <FormGroup className="m-0">
                    <label className="form-label checkbox-label">
                      Open
                      <input
                        type="checkbox"
                        onChange={(option) => handleCheck(option, 'status')}
                        value={1}
                        checked={status.includes(1)}
                      />
                      <span className="checkmark" />
                    </label>
                  </FormGroup>
                )}
                <FormGroup className="m-0">
                  <label className="form-label checkbox-label">
                    Completed
                    <input
                      type="checkbox"
                      onChange={(option) => handleCheck(option, 'status')}
                      value={2}
                      checked={status.includes(2)}
                    />
                    <span className="checkmark" />
                  </label>
                </FormGroup>
                <FormGroup className="m-0">
                  <label className="form-label checkbox-label">
                    Not Found
                    <input
                      type="checkbox"
                      onChange={(option) => handleCheck(option, 'status')}
                      value={3}
                      checked={status.includes(3)}
                    />
                    <span className="checkmark" />
                  </label>
                </FormGroup>
                <FormGroup className="m-0">
                  <label className="form-label checkbox-label">
                    No Response
                    <input
                      type="checkbox"
                      onChange={(option) => handleCheck(option, 'status')}
                      value={4}
                      checked={status.includes(4)}
                    />
                    <span className="checkmark" />
                  </label>
                </FormGroup>
                {user.roleType === userTypes.Admin && (
                  <Fragment>
                    <FormGroup className="m-0">
                      <label className="form-label checkbox-label">
                        Archived
                        <input
                          type="checkbox"
                          onChange={(option) => handleCheck(option, 'status')}
                          value={5}
                          checked={status.includes(5)}
                        />
                        <span className="checkmark" />
                      </label>
                    </FormGroup>
                    <FormGroup className="m-0">
                      <label className="form-label checkbox-label">
                        International
                        <input
                          type="checkbox"
                          onChange={() => setIsInternationalFilter((v) => !v)}
                          checked={isInternationalFilter}
                        />
                        <span className="checkmark" />
                      </label>
                    </FormGroup>
                  </Fragment>
                )}
              </div>
            </Col>
            {user.roleType === userTypes.Admin && (
              <Col lg="6">
                <FormGroup className="d-flex justify-content-lg-end m-0">
                  <Button
                    className="downloadCsvStyle"
                    onClick={() => getTracebacksCSV(filterElements, paginationParams)}
                  >
                    <i className="fa fa-download" />
                    {` Download CSV`}
                  </Button>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            <Col sm="1" className="ps-1">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  clearFilters();
                }}
              >
                Clear
              </button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="card-body-traceback">
          <CustomDataTable
            columns={
              user.roleType === userTypes.Admin ? tracebacksPageColumns : tracebacksGovPageColumns
            }
            defaultSortFieldId={paginationParams.sort}
            defaultSortAsc={paginationParams.order === 'asc'}
            defaultPage={paginationParams.page}
            defaultPageSize={paginationParams.pageSize}
            pagination={true}
            paginationTotalRows={paginationTotalRows}
            tableData={tracebacks}
            updatePaginationParams={updatePagination}
            useExpandableRows={user.roleType === userTypes.Admin}
            useExpandableRowsComponent={TracebacksExpandedComponent}
          />
        </CardBody>
      </Card>

      <div className="d-flex justify-content-center pt-4 pb-4">
        <Legend />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    user: sm.user,
    tracebacks: sm.traceback.tracebacks,
    campaignList: sm.campaign.campaigns,
    paginationTotalRows: sm.traceback.meta.TotalCount,
    tbStats: sm.traceback.tbStats
  };
};

const mapActionsToProps = {
  getTracebackList,
  getTracebackStats,
  getCampaignList,
  clearTracebacksList,
  getTracebacksCSV
};

export default connect(mapStateToProps, mapActionsToProps)(Tracebacks);
