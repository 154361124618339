import React, { FC } from 'react';
import { Label } from 'reactstrap';
import { userTypes } from '../../../enum/userTypes';
import { PersonalInfo } from '../../../interfaces/user';
import { NotProvidedReason } from '../../../interfaces/callSource';
import InputFormGroup from '../../inputFormGroup';

export interface CallRecordProps {
  user: PersonalInfo;
  downstreamId: number;
  cause: NotProvidedReason;
  explanation: string;
  handleCauseChange: (e: any) => void;
  handleCallSourceInputChange: (e: any) => void;
}
const CallRecordStep: FC<CallRecordProps> = ({
  user,
  cause,
  downstreamId,
  explanation,
  handleCauseChange,
  handleCallSourceInputChange
}) => {
  return (
    <>
      <div className="row ms-3">Why is No Record being provided?</div>
      <div className="d-flex flex-row flex-wrap mt-2">
        <Label className="telecom-label ms-3">
          Select One
          <i className="fa fa-asterisk asterisk" />
        </Label>
        <div
          className={
            user.roleType === userTypes.Admin ? 'd-flex flex-column' : 'd-flex flex-row flex-wrap'
          }
        >
          <span className="ms-2">
            <input
              type="radio"
              name="callSourceTypes"
              value="isRecordUn"
              onChange={(e) => handleCauseChange(e)}
              checked={cause === 'isRecordUn'}
              className="ms-2 me-2"
            />
            Record unavailable
          </span>
          <span className="ms-2">
            <input
              type="radio"
              name="callSourceTypes"
              value="isCallOld"
              onChange={(e) => handleCauseChange(e)}
              checked={cause === 'isCallOld'}
              className="ms-2 me-2"
            />
            Call is too old
          </span>
          <span className="ms-2">
            <input
              type="radio"
              name="callSourceTypes"
              value="isRefusing"
              onChange={(e) => handleCauseChange(e)}
              checked={cause === 'isRefusing'}
              className="ms-2 me-2"
            />
            We refuse to share this information
          </span>
          {user.roleType === userTypes.Admin && (
            <>
              <span className="ms-2">
                <input
                  type="radio"
                  name="callSourceTypes"
                  value="isProviderNonRes"
                  onChange={(e) => handleCauseChange(e)}
                  checked={cause === 'isProviderNonRes'}
                  className="ms-2 me-2"
                />
                Provider is non-responsive
              </span>
              <span className="ms-2">
                <input
                  type="radio"
                  name="callSourceTypes"
                  value="tracebackCancel"
                  onChange={(e) => handleCauseChange(e)}
                  checked={cause === 'tracebackCancel'}
                  className="ms-2 me-2"
                />
                Cancel Traceback
              </span>
              {!!downstreamId && (
                <span className="ms-2">
                  <input
                    type="radio"
                    name="callDestinationTypes"
                    value="imposter"
                    onChange={(e) => handleCauseChange(e)}
                    checked={cause === 'imposter'}
                    className="ms-2 me-2"
                  />
                  Flag this hop Provider as Imposter
                </span>
              )}
            </>
          )}
        </div>
        <div className="call-record-explanation">
          <label className="telecom-label">
            Explanation
            <i className="fa fa-asterisk asterisk" />
          </label>
          <InputFormGroup
            isReadonly={false}
            isTextarea
            inputName="explanation"
            inputId="explanation"
            inputClassName="input-hop"
            inputValue={explanation}
            inputOnChange={handleCallSourceInputChange}
            inputPlaceholder="Please leave a response explaining why you are either unable to locate the call record or are unwilling to share this information.
                                            If no match found using Calling Number, please try searching for the Called Number only."
            inputAutoComplete="off"
          />
        </div>
      </div>
    </>
  );
};

export default CallRecordStep;
