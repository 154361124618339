import React, { FC, FormEventHandler, useEffect } from 'react';
import ProvidersSelect, { ProviderItem } from '../../ProvidersSelect';
import { PersonalInfo } from '../../../interfaces/user';
import { userTypes } from '../../../enum/userTypes';
import { stateMappings } from '../../../redux/stateMappings';
import { connect } from 'react-redux';
import { getProviderIsNonResponsive } from '../../../redux/provider/thunks';
import { Label } from 'reactstrap';
import InputFormGroup from '../../inputFormGroup';
import { getApiFormattedDate } from '../../../lib/utilities';
import { validatePhoneNumber } from '../../../lib/phoneNumber';

interface UpstreamVoiceProps {
  isNrProvider: boolean;
  getProviderIsNonResponsive: Function;
  user: PersonalInfo;
  hopDetail: HopDetails;
  selectedProvider: number;
  handleProviderChange: (e: any) => void;
  selectedProviderData?: ProviderItem;
  extraContactEmail: string;
  extraCommentRmd: string;
  newTracebackTime: string;
  forwardedCall: string;
  extraContactEmailError: string;
  extraCommentRmdError: string;
  setNewTracebackTime: (value: string) => void;
  setForwardedCall: (value: string) => void;
  handleCallSourceInputChange: FormEventHandler<HTMLInputElement>;
  newProvider: () => void;
}

export interface HopDetails {
  hopId: number;
  hopProviderId: number;
  hopStatus: number;
  hopSequence: number;
  isInternational: boolean;
  forwardedCall: string;
}

const UpstreamVoiceStep: FC<UpstreamVoiceProps> = ({
  user,
  hopDetail,
  selectedProvider,
  handleProviderChange,
  selectedProviderData,
  isNrProvider,
  getProviderIsNonResponsive,
  extraContactEmail,
  extraCommentRmd,
  newTracebackTime,
  forwardedCall,
  extraContactEmailError,
  extraCommentRmdError,
  setNewTracebackTime,
  setForwardedCall,
  handleCallSourceInputChange,
  newProvider
}) => {
  useEffect(() => {
    if (selectedProvider) getProviderIsNonResponsive(selectedProvider);
  }, [selectedProvider]);
  return (
    <div>
      <div className="row mb-3">
        <div className="col-md-4 col-9">Select Upstream Voice Service Provider</div>
        <div className="col-md-3 col-12 p-0">
          <ProvidersSelect
            onChange={(e) => handleProviderChange(e)}
            isSearchable
            isClearable
            className={'customselect-large'}
            option={selectedProvider}
            removeId={hopDetail.hopProviderId}
            placeholder="All Providers"
          />
        </div>
        <div className="col-md-5 col-12">
          {user.roleType !== userTypes.Admin && (
            <>
              <p className="mb-0">Is your upstream provider not listed in the dropdown?</p>
              <span
                className="span-cell blue"
                id="newProvider"
                style={{ cursor: 'pointer' }}
                onClick={newProvider}
              >
                + Add New Provider
              </span>
            </>
          )}
        </div>
      </div>
      {isNrProvider && selectedProviderData && (
        <div className="d-flex flex-row mb-3">
          <Label className="telecom-label w-50">Alert for upstream provider:</Label>
          <span className="text-danger">
            The upstream provider selected, {selectedProviderData.name} , has been non responsive in
            prior traceback requests. US providers are required to respond to traceback requests.
            Please encourage your connecting partner who you accept traffic from, to comply with
            this and future traceback requests.{' '}
          </span>
        </div>
      )}
      {selectedProviderData?.badEmails && (
        <div className="d-flex flex-row">
          <div>
            <Label className="telecom-label">Alternative Contact Email</Label>
          </div>
          <div className={'upstream-comment'}>
            <InputFormGroup
              isReadonly={false}
              inputName="extraContactEmail"
              inputId="extraContactEmail"
              inputClassName="input-provider"
              inputValue={extraContactEmail}
              inputOnChange={handleCallSourceInputChange}
              inputPlaceholder=""
              inputAutoComplete="off"
              errorMessage={extraContactEmailError}
            />
          </div>
        </div>
      )}
      {!hopDetail.isInternational &&
        selectedProviderData?.noRmd &&
        user.providerCountry === 'United States' && (
          <div className="d-flex flex-row">
            <div>
              <Label className="telecom-label">Comment for No RMDs Provider</Label>
            </div>
            <div className={'upstream-comment'}>
              <InputFormGroup
                isReadonly={false}
                inputName="extraCommentRmd"
                inputId="extraCommentRmd"
                inputClassName="input-provider"
                inputValue={extraCommentRmd}
                inputOnChange={handleCallSourceInputChange}
                inputPlaceholder=""
                inputAutoComplete="off"
                errorMessage={extraCommentRmdError}
              />
            </div>
          </div>
        )}
      {hopDetail.hopId && hopDetail.hopSequence === 1 && hopDetail.hopStatus === 1 && (
        <div className="d-flex justify-content-center mt-2">
          <div className="d-flex flex-column">
            <label className="telecom-label ms-2 me-2">Corrected traceback time:</label>
          </div>
          <div className="d-flex flex-column me-5">
            <InputFormGroup
              inputName="newTracebackTime"
              inputId="newTracebackTime"
              inputClassName="input-traceback"
              inputValue={newTracebackTime}
              inputPlaceholder="2020-03-07 19:55"
              inputOnChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewTracebackTime(e.target.value);
              }}
              inputAutoComplete="off"
              errorMessage={
                newTracebackTime && !getApiFormattedDate(newTracebackTime)
                  ? 'Please provide a valid date.'
                  : ''
              }
            />
          </div>
        </div>
      )}
      {hopDetail.hopId && (!hopDetail.forwardedCall || hopDetail.forwardedCall.trim() === '') && (
        <div className="d-flex justify-content-center mt-2">
          <div className="d-flex flex-column">
            <Label className="telecom-label ms-2">Forwarded Call:</Label>
          </div>
          <div className="d-flex flex-column me-1">
            <InputFormGroup
              inputName="forwardedCall"
              inputId="forwardedCall"
              inputClassName="input-traceback"
              inputValue={forwardedCall}
              inputPlaceholder="2025558387"
              inputOnChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setForwardedCall(e.target.value);
              }}
              inputAutoComplete="off"
              errorMessage={
                !forwardedCall || validatePhoneNumber(forwardedCall)
                  ? ''
                  : 'Please provide a valid phone number.'
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    isNrProvider: sm.provider.isNrProvider
  };
};

const mapActionsToProps = { getProviderIsNonResponsive };
export default connect(mapStateToProps, mapActionsToProps)(UpstreamVoiceStep);
