import React, { FC, Fragment, useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Col, FormGroup, Row } from 'reactstrap';
import {
  IncidentCampaignTableObject,
  IncidentCampaignTableRow
} from '../../../interfaces/incident';
import { Pagination, defaultPagination } from '../../../interfaces/pagination';
import {
  decodeQueryParams,
  encodeQueryParams,
  getFromLocalStorage,
  saveToLocalStorage
} from '../../../lib/history-utils';
import { utcNow } from '../../../lib/utilities';
import { getIncidentCampaignNames, getIncidentSummaryObject } from '../../../redux/incident/thunks';
import { stateMappings } from '../../../redux/stateMappings';
import CustomDataTable from '../../CustomDataTable';
import CustomSelect, { SelectOption } from '../../CustomSelect';
import IncidentCampaignsSearchTable from './IncidentCampaignsSearchTable';
import TriggerIngest from './TriggerIngest';
import {
  addDays,
  getAllIncidentSummariesColumns,
  getIncidentSummariesColumns,
  getWeekFilterText
} from './utilities';

interface IProps {
  incidentSummary: IncidentCampaignTableObject;
  campaignNames: { label: string; value: number }[];
  paginationTotalRows: number;
  getIncidentSummaryObject: Function;
  getIncidentCampaignNames: Function;
  activeSource: number;
  selectIncidentCampaign: (row: IncidentCampaignTableRow) => void;
}

const PAGENAME = 'incidentSummaries';

const IncidentSummaries: FC<IProps> = ({
  incidentSummary,
  activeSource,
  paginationTotalRows,
  getIncidentSummaryObject,
  selectIncidentCampaign,
  getIncidentCampaignNames,
  campaignNames
}) => {
  const { hash } = useLocation();
  const savedSearchDetails = decodeQueryParams(PAGENAME, getFromLocalStorage(PAGENAME) || '');
  const [currentDate, setCurrentDate] = useState(utcNow());
  const [week, setWeek] = useState(0);
  const [pagination, setPagination] = useState<Pagination>(
    savedSearchDetails.paginationParams || {
      ...defaultPagination(),
      sort: 'mostRecentTracebackDate',
      order: 'desc'
    }
  );
  const [transcriptFilter, setTranscriptFilter] = useState(
    (savedSearchDetails.filterElements && savedSearchDetails.filterElements.transcriptFilter) || ''
  );
  const [incidentSummaryValue, setIncidentSummaryValue] = useState<SelectOption | null>(
    (savedSearchDetails.filterElements && savedSearchDetails.filterElements.incidentSummaryValue) ||
      null
  );

  const handleClearFilter = (event: any) => {
    event.preventDefault();
    setTranscriptFilter('');
    setIncidentSummaryValue(null);
    setWeek(0);
    setCurrentDate(utcNow());
    setPagination({
      ...defaultPagination(),
      sort: 'mostRecentTracebackDate',
      order: 'desc'
    });
  };

  const handleSearch = () => {
    getIncidentSummaryObject(
      activeSource,
      incidentSummaryValue ? incidentSummaryValue.value : 0,
      week,
      pagination,
      transcriptFilter
    );
  };
  useEffect(() => {
    getIncidentCampaignNames();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [activeSource, week, pagination]);

  useEffect(() => {
    saveToLocalStorage(
      PAGENAME,
      encodeQueryParams(
        PAGENAME,
        {
          paginationParams: pagination,
          filterElements: {
            transcriptFilter: transcriptFilter,
            incidentSummaryValue: incidentSummaryValue
          }
        },
        {}
      )
    );
  }, [pagination, incidentSummaryValue, transcriptFilter]);

  const getNextWeek = () => {
    if (!week) return; // Can't go beyond current date
    setWeek(week + 1);
    setCurrentDate(addDays(currentDate, 7));
  };

  const getThisWeek = () => {
    if (!week) return;
    setWeek(0);
    setCurrentDate(utcNow());
  };

  const getPrevWeek = () => {
    if (week === incidentSummary.maxWeekOffset) return; // Can't go beyond current date
    setWeek(week - 1);
    setCurrentDate(addDays(currentDate, -7));
  };

  const getMaxWeek = () => {
    if (week === incidentSummary.maxWeekOffset) return;
    setWeek(incidentSummary.maxWeekOffset);
    setCurrentDate(addDays(currentDate, 7 * incidentSummary.maxWeekOffset));
  };
  return (
    <Fragment>
      <Row>
        {activeSource !== 0 && <Col md="4"></Col>}
        <Col md="4">
          <div className="d-flex justify-content-center text-large">
            <span>
              <span
                style={{ cursor: week ? 'pointer' : '' }}
                onClick={getThisWeek}
                className={!week ? 'mx-1 text-light-grey text-xlarge' : 'mx-1 text-xlarge'}
              >
                &lt;&lt;
              </span>
              <span
                style={{ cursor: week ? 'pointer' : '' }}
                onClick={getNextWeek}
                className={!week ? 'mx-1 text-light-grey text-xlarge' : 'mx-1 text-xlarge'}
              >
                &lt;
              </span>
              <span className="mx-1 text-navy">{getWeekFilterText(currentDate)}</span>
              <span
                style={{
                  cursor: week !== incidentSummary.maxWeekOffset ? 'pointer' : ''
                }}
                onClick={getPrevWeek}
                className={
                  week === incidentSummary.maxWeekOffset
                    ? 'mx-1 text-light-grey text-xlarge'
                    : 'mx-1 text-xlarge'
                }
              >
                &gt;
              </span>
              <span
                style={{
                  cursor: week !== incidentSummary.maxWeekOffset ? 'pointer' : ''
                }}
                onClick={getMaxWeek}
                className={
                  week === incidentSummary.maxWeekOffset
                    ? 'mx-1 text-light-grey text-xlarge'
                    : 'mx-1 text-xlarge'
                }
              >
                &gt;&gt;
              </span>
            </span>
          </div>
        </Col>
        <Col
          md="2"
          className={`text-center text-xlarge ${
            incidentSummary.newIncidents >= 0 ? 'text-green' : 'text-red'
          }`}
        >
          {incidentSummary.newIncidents || 0}
        </Col>
        <Col md="1" className="text-center text-blue text-xlarge">
          {incidentSummary.closedTracebackPercentage
            ? incidentSummary.closedTracebackPercentage.toFixed(2)
            : 0}
          %
        </Col>
        {activeSource === 0 && (
          <Fragment>
            <div className="col-2">
              <label className="incident-campaign-filter-label">Campaign Name</label>
            </div>
            <div className="col-2">
              <label className="incident-campaign-filter-label">Transcript Body</label>
            </div>
          </Fragment>
        )}
      </Row>
      <div className="d-flex flex-row row mb-2">
        {activeSource === 10057 ? (
          <div className="col-4">
            <TriggerIngest />
          </div>
        ) : (
          activeSource !== 0 && <div className="col-4" />
        )}
        <div className="col-4 text-center text-grey">Time Period</div>
        <div className="col-2 text-center">New Incidents WoW</div>
        <div className="col-1 text-center">Traced Back</div>
        {activeSource === 0 && (
          <Fragment>
            <div className="col-2">
              <CustomSelect
                typeLabel=""
                className="customselect-small"
                classNamePrefix="customselect"
                placeholder="Search Campaign Names"
                isSearchable
                selectedOption={incidentSummaryValue}
                getOptions={campaignNames}
                setSelectedOption={(e: any) => setIncidentSummaryValue(e)}
              />
            </div>
            <div className="col-2">
              <FormGroup>
                <div className="input-group">
                  <DebounceInput
                    debounceTimeout={1000}
                    onChange={(event: any) => {
                      event.preventDefault();
                      setTranscriptFilter(event.target.value);
                    }}
                    value={transcriptFilter}
                    className="input-comment form-control"
                    placeholder="Search Transcript Body"
                    name="transcript-filter"
                    id="transcript-filter"
                  />
                </div>
              </FormGroup>
            </div>
            <div className="d-flex col-1 justify-content-around">
              <a
                href="#"
                onClick={(event: any) => {
                  event.preventDefault();
                  handleSearch();
                  return false;
                }}
              >
                Search
              </a>
              <a href="#" onClick={handleClearFilter} className="ms-2">
                Clear
              </a>
            </div>
          </Fragment>
        )}
      </div>
      <CustomDataTable
        tableData={incidentSummary.incidentTableRows || []}
        columns={
          activeSource === 0
            ? getAllIncidentSummariesColumns
            : getIncidentSummariesColumns(week, hash, selectIncidentCampaign)
        }
        defaultSortFieldId={pagination.sort}
        defaultSortAsc={pagination.order === 'asc'}
        defaultPage={pagination.page}
        defaultPageSize={pagination.pageSize}
        pagination={true}
        paginationTotalRows={paginationTotalRows}
        updatePaginationParams={setPagination}
        useExpandableRows={activeSource === 0}
        useExpandableRowsComponent={({ data }) =>
          data ? (
            <IncidentCampaignsSearchTable
              data={data}
              key={`IncidentCampaignsSearchTable-${data.incidentCampaignId}`}
              pagination={{
                sort: 'id',
                order: 'desc',
                page: 1,
                pageSize: 5
              }}
              forAllSources
              transcriptFilter={transcriptFilter}
            />
          ) : null
        }
      />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    incidentSummary: sm.incident.incidentSummary,
    campaignNames: sm.incident.campaignNames,
    paginationTotalRows: sm.incident.metaSummary.TotalCount
  };
};

const mapActionsToProps = {
  getIncidentSummaryObject,
  getIncidentCampaignNames
};

export default connect(mapStateToProps, mapActionsToProps)(IncidentSummaries);
