import React, { FC, Fragment, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { connect } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './App.scss';
import Default from './components/404';
import AuthRoute from './components/AuthRoute';
import Footer from './components/Footer';
import Layout from './components/Layout';
import SetupForm from './components/SetupForm';
import LoadingIndicator from './components/shared/LoadingIndicator';
import { setupDataManager } from './dataManager/dataManager';
import { userTypes } from './enum/userTypes';
import { Campaign } from './interfaces/campaign';
import { Provider } from './interfaces/provider';
import { PersonalInfo } from './interfaces/user';
import { installCheckVersionHook } from './lib/checkVersionHook';
import About from './pages/About';
import AddEvent from './pages/AddEvent';
import AddRequest from './pages/AddRequest';
import AdminDashboard from './pages/AdminDashboard';
import AddCampaign from './pages/CampaignAdd';
import CampaignDetail from './pages/CampaignDetail';
import Campaigns from './pages/Campaigns';
import CommentAdd from './pages/CommentAdd';
import CommentDetail from './pages/CommentDetail';
import Comments from './pages/Comments';
import CreateNewPassword from './pages/CreateNewPassword';
import DnoProviders from './pages/DnoProviders';
import EditEvent from './pages/EditEvent';
import EmailRedirect from './pages/EmailRedirect';
import GovCommentAdd from './pages/GovCommentAdd';
import HomePage from './pages/HomePage';
import HopDetail from './pages/HopDetail';
import Hops from './pages/Hops';
import ImportTracebacks from './pages/ImportTracebacks';
import Login from './pages/Login';
import Account from './pages/MyAccount';
import NavRequest from './pages/NavRequest';
import Partners from './pages/Partners';
import Privacy from './pages/Privacy';
import AddProvider from './pages/ProviderAdd';
import ProviderDetail from './pages/ProviderDetail';
import ProviderInsight from './pages/ProviderInsight';
import ProviderSummary from './pages/ProviderSummary';
import Providers from './pages/Providers';
import ProvidersInfo from './pages/ProvidersInfo';
import ResetPassword from './pages/ResetPassword';
import SentEmail from './pages/SentEmail';
import SignOutElement from './pages/SignOutElement';
import StirShakenIssues from './pages/StirShakenIssues';
import Support from './pages/Support';
import TermsOfUse from './pages/TermsOfUse';
import TfHopDetail from './pages/TfHopDetail';
import TfHops from './pages/TfHops';
import AddTraceback from './pages/TracebackAdd';
import TracebackCommentAdd from './pages/TracebackCommentAdd';
import TracebackDetail from './pages/TracebackDetail';
import Tracebacks from './pages/Tracebacks';
import TraceforwardAdd from './pages/TraceforwardAdd';
import TraceforwardDetail from './pages/TraceforwardDetail';
import Traceforwards from './pages/Traceforwards';
import UserDetail from './pages/UserDetail';
import ZendeskLogin from './pages/ZendeskLogin';
import { rememberPage, signOut, staySignedIn } from './redux/auth/thunks';
import { getProviderNameList } from './redux/provider/thunks';
import { stateMappings } from './redux/stateMappings';

interface IProps {
  user: PersonalInfo;
  isAuthenticated: boolean;
  isLoading: boolean;
  setupDataManager: Function;
  staySignedIn: Function;
  signOut: Function;
  campaign: Campaign;
  provider: Provider;
  loadingTree: {};
  getProviderNameList: Function;
  rememberPage: Function;
}

const routes = [
  { element: <Login />, path: '/login' },
  { element: <About />, path: '/about' },
  { element: <Privacy />, path: '/Privacy' },
  { element: <TermsOfUse />, path: '/termsofuse' },
  { element: <Support />, path: '/support' },

  { element: <ResetPassword />, path: '/reset-password' },

  { element: <SentEmail />, path: '/sent-email' },

  { element: <EmailRedirect />, path: '/email-redirect' },

  { element: <SetupForm />, path: '/setup-account', useAuth: true },

  { element: <CreateNewPassword />, path: '/create-password', useAuth: true },

  { element: <Account />, path: '/account', useAuth: true },

  { element: <Campaigns />, path: '/campaigns', useAuth: true },

  { element: <AddCampaign />, path: '/campaigns/add', useAuth: true },

  { element: <CampaignDetail />, path: '/campaigns/campaign/:id', useAuth: true },

  { element: <Tracebacks />, path: '/tracebacks', useAuth: true },
  { element: <AddTraceback />, path: '/tracebacks/add', useAuth: true },
  { element: <TracebackDetail />, path: '/tracebacks/traceback/:id', useAuth: true },

  { element: <Traceforwards />, path: '/traceforwards', useAuth: true },
  { element: <TraceforwardAdd />, path: '/traceforwards/add', useAuth: true },
  { element: <TraceforwardDetail />, path: '/traceforwards/traceforward/:id', useAuth: true },

  { element: <UserDetail />, path: '/users/user/:id', useAuth: true },

  { element: <Hops />, path: '/hops', useAuth: true },
  { element: <Hops />, path: '/hops/provider/:id', useAuth: true },
  { element: <Hops />, path: '/hops/traceback/:id', useAuth: true },
  { element: <HopDetail />, path: '/hops/hop/:id', useAuth: true },

  { element: <TfHops />, path: '/tfhops', useAuth: true },
  { element: <TfHopDetail />, path: '/tfhops/tfhop/:id', useAuth: true },

  { element: <Providers />, path: '/providers', useAuth: true },

  { element: <AddProvider />, path: '/providers/add', useAuth: true },

  { element: <ProviderDetail />, path: '/providers/provider/:id', useAuth: true },

  { element: <ProviderSummary />, path: '/providers/provider/summaries/:id', useAuth: true },
  { element: <ProviderInsight />, path: '/providers/provider/insights/:id', useAuth: true },

  { element: <HomePage />, path: '/', useAuth: true },

  { element: <Comments />, path: '/comments', useAuth: true },
  { element: <CommentAdd />, path: '/comments/add', useAuth: true },
  { element: <TracebackCommentAdd />, path: '/traceback-comments/add', useAuth: true },

  { element: <GovCommentAdd />, path: '/gov-comments/add', useAuth: true },

  { element: <CommentDetail />, path: '/comments/comment/:id', useAuth: true },

  { element: <AdminDashboard />, path: '/admin-dashboard', useAuth: true },

  { element: <Partners />, path: '/partners', useAuth: true },

  { element: <AddEvent />, path: '/partners/event/add', useAuth: true },

  { element: <EditEvent />, path: '/partners/event/:id', useAuth: true },

  { element: <DnoProviders />, path: '/dno-providers', useAuth: true },

  { element: <ZendeskLogin />, path: '/zendesk/token', useAuth: true },
  { element: <ImportTracebacks />, path: '/tracebacks/import', useAuth: true },
  { element: <SignOutElement />, path: '/zendesk/logout', useAuth: true },
  { element: <ProvidersInfo />, path: '/nrProviderInfo', useAuth: true },
  { element: <ProvidersInfo />, path: '/newProviderInfo', useAuth: true },
  { element: <ProvidersInfo />, path: '/nfProviderInfo', useAuth: true },
  { element: <ProvidersInfo />, path: '/followingProviders', useAuth: true },
  { element: <NavRequest />, path: '/requests', useAuth: true },
  { element: <AddRequest />, path: '/requests/add', useAuth: true },

  { element: <StirShakenIssues />, path: '/providers/stirshakens', useAuth: true },

  { element: <Default />, path: '/404' },

  { element: <Default />, path: '*' }
];

const App: FC<IProps> = ({
  isAuthenticated,
  signOut,
  staySignedIn,
  setupDataManager,
  isLoading,
  user,
  campaign,
  provider,
  getProviderNameList,
  rememberPage
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [title, setTitle] = useState('ITG - Portal');
  const [idleTimeoutModalOpen, setIdleTimeoutModalOpen] = useState(false);

  useEffect(() => {
    const { pathname, hash } = location;
    let hop: string[] = [];
    let traceback: string[] = [];
    let tfhop: string[] = [];
    let traceforward: string[] = [];

    if (pathname.startsWith('/tracebacks')) {
      traceback = pathname.split('/');
    }
    if (pathname.startsWith('/hops')) {
      hop = pathname.split('/');
    }
    if (pathname.startsWith('/tfhops')) {
      tfhop = pathname.split('/');
    }
    if (pathname.startsWith('/traceforwards')) {
      traceforward = pathname.split('/');
    }
    if (pathname === '/admin-dashboard') {
      switch (hash) {
        case '#settings':
          setTitle('ITG - Settings');
          break;
        case '#history':
          setTitle('ITG - History');
          break;
        case '#reporting':
          setTitle('ITG - Reporting');
          break;
        case '#users':
          setTitle('ITG - Users');
          break;
        case '#dno':
          setTitle('ITG - Dno');
          break;
        case '#messaging':
          setTitle('ITG - Messages');
          break;
        default:
          if (hash.startsWith('#incidents')) setTitle('ITG - Incidents');
          else setTitle('ITG - Admin');
          break;
      }
    } else {
      switch (true) {
        case traceback.length > 2:
          setTitle('ITG - Traceback - ' + traceback[traceback.length - 1]);
          break;
        case traceback.length === 2:
          setTitle('ITG - Tracebacks');
          break;
        case traceforward.length === 2:
          setTitle('ITG - Traceforwards');
          break;
        case traceforward.length > 2:
          setTitle('ITG - Traceforward - ' + traceforward[traceforward.length - 1]);
          break;
        case hop.length === 2:
          setTitle('ITG - Hops');
          break;
        case hop.length > 2:
          setTitle('ITG - Hop - ' + hop[hop.length - 1]);
          break;
        case tfhop.length === 2:
          setTitle('ITG - TfHops');
          break;
        case tfhop.length > 2:
          setTitle('ITG - TfHop - ' + tfhop[tfhop.length - 1]);
          break;
        case pathname.startsWith('/providers/stirshakens'):
          setTitle('ITG - StirShaken Issues');
          break;
        case pathname.startsWith('/providers'):
          setTitle('ITG - Providers');
          break;
        case pathname.startsWith('/campaigns'):
          setTitle('ITG - Campaigns');
          break;
        case pathname.startsWith('/comments'):
          setTitle('ITG - Comments');
          break;
        case pathname.startsWith('/account'):
          setTitle('ITG - My Account');
          break;
        case pathname.startsWith('/requests'):
          if (hash === '#result') setTitle('ITG - Request Results');
          else if (hash === '#add') setTitle('ITG - Add Request');
          else setTitle('ITG - Requests');
          break;
        case pathname.startsWith('/partners'):
          setTitle('ITG - Partners');
          break;
        default:
          setTitle('ITG - Portal');
      }
    }
  }, [location]);
  useEffect(() => {
    const { pathname } = location;
    let campaignLocation: string[] = [];
    if (campaign) {
      if (pathname.startsWith('/campaigns')) {
        campaignLocation = pathname.split('/');
        if (
          campaignLocation.length > 2 &&
          Number(campaignLocation[campaignLocation.length - 1]) === campaign.campaignId
        ) {
          setTitle('ITG - Campaign - ' + campaign.name);
        } else setTitle('ITG - Campaigns');
      }
    }
  }, [campaign]);
  useEffect(() => {
    const { pathname } = location;
    let providerLocation: string[] = [];

    if (provider) {
      if (pathname.startsWith('/providers')) {
        providerLocation = pathname.split('/');
        if (
          providerLocation.length > 2 &&
          Number(providerLocation[providerLocation.length - 1]) === provider.providerId
        ) {
          setTitle('ITG - Provider - ' + provider.name);
        } else setTitle('ITG - Providers');
      }
    }
  }, [provider]);
  const idleOut = () => {
    if (isAuthenticated) signOut();
    rememberPage(location.pathname + location.search + location.hash);
    navigate('/login?errorMessage=inactivity');
    setIdleTimeoutModalOpen(false);
  };

  const idleTimer = useIdleTimer({
    element: document,
    onIdle: () => {
      idleOut();
    },
    onActive: () => {
      setIdleTimeoutModalOpen(false);
    },
    onPrompt: () => {
      setIdleTimeoutModalOpen(true);
    },
    startOnMount: false,
    debounce: 250,
    timeout: 15 * 60 * 1e3 + 30 * 1e3,
    crossTab: true,
    syncTimers: 200,
    promptBeforeIdle: 30 * 1e3
  });

  useEffect(() => {
    if (isAuthenticated) {
      setupDataManager(userTypes.Admin === user.roleType);
      staySignedIn();
      idleTimer.reset();
    } else {
      idleTimer.pause();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    if (
      user.roleType === userTypes.DnoUser ||
      user.roleType === userTypes.PartnerUser ||
      !isAuthenticated
    )
      return;
    const timer = setInterval(() => {
      getProviderNameList();
    }, 300000); //5 minutes to refresh the provider names list

    return () => clearInterval(timer);
  }, []);

  installCheckVersionHook({ duration: 5 * 60 * 1000 });

  return (
    <Fragment>
      <Modal
        centered
        className="modal-template"
        isOpen={idleTimeoutModalOpen && isAuthenticated}
        toggle={() => {
          idleTimer.activate();
        }}
      >
        <ModalHeader>Session Timeout</ModalHeader>
        <ModalBody>
          You're being timed out due to inactivity. Please choose to stay signed in or to logoff.
          Otherwise, you will be logged off automatically.
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              idleTimer.activate();
            }}
          >
            Continue
          </Button>{' '}
          <Button color="primary" onClick={idleOut}>
            Sign Out
          </Button>{' '}
        </ModalFooter>
      </Modal>
      {isLoading && <LoadingIndicator />}
      <Layout>
        <Routes>
          {routes.map(({ path, element, useAuth }) =>
            useAuth ? (
              <Route key={path} path={path} element={<AuthRoute element={element} path={path} />} />
            ) : (
              <Route key={path} path={path} element={element} />
            )
          )}
        </Routes>
      </Layout>
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    user: sm.user,
    isAuthenticated: sm.isAuthenticated,
    isLoading: sm.loading,
    loadingTree: sm.loadingTree,
    campaign: sm.campaign.campaign,
    provider: sm.provider.provider
  };
};

const mapActionsToProps = {
  setupDataManager,
  staySignedIn,
  signOut,
  getProviderNameList,
  rememberPage
};

export default connect(mapStateToProps, mapActionsToProps)(App);
