import React, { FC, useState } from 'react';
import { useLocation } from 'react-router';
import { CardBody, Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import InsightsSettings from '../Settings/InsightsSettings';
import ReputationSettings from '../Settings/ReputationSettings';

const NavSettings: FC = () => {
  const { hash } = useLocation();
  const [activeTab, setActiveTab] = useState(
    hash.slice(1) === 'settings' ? 'reputation-settings' : hash.slice(1)
  );
  return (
    <CardBody>
      <Nav tabs className="tab-list">
        <NavItem className="nav-item-horizontal">
          <a
            className={'tab-list-item' + (activeTab === 'reputation-settings' ? ' active' : '')}
            href="admin-dashboard#reputation-settings"
            onClick={() => setActiveTab('reputation-settings')}
          >
            Status
          </a>
          <a
            className={'tab-list-item' + (activeTab === 'insights-settings' ? ' active' : '')}
            href="admin-dashboard#insights-settings"
            onClick={() => setActiveTab('insights-settings')}
          >
            Insights
          </a>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="reputation-settings">
          <ReputationSettings />
        </TabPane>
        <TabPane tabId="insights-settings">
          <InsightsSettings />
        </TabPane>
      </TabContent>
    </CardBody>
  );
};

export default NavSettings;
